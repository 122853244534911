import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFamilyMembers,
  updateFamilyMember,
  deleteFamilyMember,
} from "../store/FamilySlice";
import DOMPurify from "dompurify";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import logger from "../utils/logger";
import Typography from "@mui/material/Typography";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const phoneRegex = /^[0-9]{10,15}$/;

const EditFamily = () => {
  const dispatch = useDispatch();
  const { familyMembers, loading, error } = useSelector(
    (state) => state.family
  );
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [editForm, setEditForm] = useState({
    name: "",
    relation: "",
    email: "",
    phoneNumber: "",
  });
  const [refresh, setRefresh] = useState(false);
  const navigation = useNavigate();
  const [dob, setDob] = useState(null);

  useEffect(() => {
    dispatch(fetchFamilyMembers());
    setRefresh(false);
  }, [dispatch, refresh]);

  useEffect(() => {
    if (selectedIndex !== null) {
      const member = familyMembers[selectedIndex];
      setEditForm(member);
      setDob(member.dob ? new Date(member.dob) : null);
    }
  }, [selectedIndex, familyMembers]);

  const handleSelect = (index) => {
    setSelectedIndex(index);
    setEditForm(familyMembers[index]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = DOMPurify.sanitize(value);
    setEditForm({ ...editForm, [name]: sanitizedValue });
  };

  const handleSave = async () => {
    if (!editForm.name.trim() || !editForm.relation.trim()) {
      alert("Name and Relation are required.");
      return;
    }

    if (
      (editForm.email && !emailRegex.test(editForm.email)) ||
      !editForm.email
    ) {
      alert("Please enter a valid email.");
      return;
    }

    if (editForm.phoneNumber && !phoneRegex.test(editForm.phoneNumber)) {
      alert("Please enter a valid phone number with only numerical values.");
      return;
    }

    await dispatch(
      updateFamilyMember(
        { ...editForm, dob: dob ? dob : null },
        familyMembers[selectedIndex].id
      )
    );
    logger.info("Family member update request dispatched");
    setSelectedIndex(null);
    setRefresh(true);
    navigation("/profile");
  };

  const handleCancel = () => {
    navigation("/profile");
  };

  const handleDelete = () => {
    if (
      window.confirm(
        "Are you sure you want to delete this family member? This action cannot be undone."
      )
    ) {
      dispatch(deleteFamilyMember(familyMembers[selectedIndex].email));
      logger.info("Family member delete request dispatched");
      setSelectedIndex(null);
    }
    navigation("/profile");
  };

  const buttonStyle = {
    fontSize: "18px",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#E29578",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    borderRadius: 10,
    fontFamily: "'Days One', sans-serif",
  };

  const buttonHoverStyle = {
    backgroundColor: "#FFDDD2",
  };

  return (
    <div
      style={{
        padding: "40px",
        maxWidth: "600px",
        marginTop: "50px",
        margin: "auto",
        fontSize: "18px",
        backgroundColor: "#f9f9f9",
        borderRadius: "10px",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        style={{
          fontFamily: "'Days One', sans-serif",
          fontStyle: "italic",
          marginBottom: 20,
        }}
      >
        Edit Family Member
      </Typography>
      {loading && (
        <p style={{ fontFamily: "Verdana, sans-serif" }}>Loading...</p>
      )}
      {error && (
        <p style={{ fontFamily: "Verdana, sans-serif" }}>Error: {error}</p>
      )}
      <div style={{ flex: 1, marginRight: "20px", padding: "10px" }}>
        {familyMembers.map((member, index) => (
          <div
            key={index}
            onClick={() => handleSelect(index)}
            style={{
              cursor: "pointer",
              fontSize: 20,
              padding: 5,
              color: selectedIndex === index ? "blue" : "black",
              fontFamily: "'Days One', sans-serif",
            }}
          >
            {member.name}
          </div>
        ))}
      </div>
      {selectedIndex !== null && (
        <div
          style={{
            display: "flex",
            flex: 2,
            flexDirection: "column",
            fontSize: 18,
          }}
        >
          <label
            style={{
              marginBottom: 10,
              fontWeight: "bold",
              fontFamily: "'Days One', sans-serif",
            }}
          >
            Name:
          </label>
          <input
            type="text"
            name="name"
            value={editForm.name}
            onChange={handleChange}
            placeholder="Name"
            style={{
              marginBottom: 20,
              fontSize: 18,
              padding: "10px",
              borderRadius: "10px",
              border: "1px solid #ccc",
              fontFamily: "Verdana, sans-serif",
            }}
          />
          <label
            style={{
              marginBottom: 10,
              fontWeight: "bold",
              fontFamily: "'Days One', sans-serif",
            }}
          >
            Relation:
          </label>
          <input
            type="text"
            name="relation"
            value={editForm.relation}
            onChange={handleChange}
            placeholder="Relation"
            style={{
              marginBottom: 20,
              fontSize: 18,
              padding: "10px",
              borderRadius: "10px",
              border: "1px solid #ccc",
              fontFamily: "Verdana, sans-serif",
            }}
          />
          <label
            style={{
              marginBottom: 10,
              fontWeight: "bold",
              fontFamily: "'Days One', sans-serif",
            }}
          >
            Email:
          </label>
          <input
            type="email"
            name="email"
            value={editForm.email}
            onChange={handleChange}
            placeholder="Email"
            style={{
              marginBottom: 20,
              fontSize: 18,
              padding: "10px",
              borderRadius: "10px",
              border: "1px solid #ccc",
              fontFamily: "Verdana, sans-serif",
            }}
          />
          <label
            style={{
              marginBottom: 10,
              fontWeight: "bold",
              fontFamily: "'Days One', sans-serif",
            }}
          >
            Phone Number (Optional):
          </label>
          <input
            type="text"
            name="phoneNumber"
            value={editForm.phoneNumber}
            onChange={handleChange}
            placeholder="Phone Number"
            style={{
              marginBottom: 20,
              fontSize: 18,
              padding: "10px",
              borderRadius: "10px",
              border: "1px solid #ccc",
              fontFamily: "Verdana, sans-serif",
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date of Birth (Optional)"
              value={dob}
              onChange={(newValue) => setDob(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <button
              onClick={handleSave}
              style={{
                ...buttonStyle,
                width: "48%",
                fontFamily: "'Days One', sans-serif",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonHoverStyle.backgroundColor)
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonStyle.backgroundColor)
              }
              onFocus={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonHoverStyle.backgroundColor)
              } // Added onFocus
              onBlur={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonStyle.backgroundColor)
              } // Added onBlur
            >
              Save
            </button>
            <button
              onClick={handleCancel}
              style={{
                ...buttonStyle,
                width: "48%",
                fontFamily: "'Days One', sans-serif",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonHoverStyle.backgroundColor)
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonStyle.backgroundColor)
              }
              onFocus={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonHoverStyle.backgroundColor)
              } // Added onFocus
              onBlur={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonStyle.backgroundColor)
              } // Added onBlur
            >
              Cancel
            </button>
            <button
              onClick={handleDelete}
              style={{
                ...buttonStyle,
                width: "48%",
                backgroundColor: "red",
                fontFamily: "'Days One', sans-serif",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonHoverStyle.backgroundColor)
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonStyle.backgroundColor)
              }
              onFocus={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonHoverStyle.backgroundColor)
              } // Added onFocus
              onBlur={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonStyle.backgroundColor)
              } // Added onBlur
            >
              Delete
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditFamily;
