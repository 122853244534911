import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../components/axiosConfig";
import { clearAllData } from "./fetchBackendData";

// Async thunk for creating a PayPal subscription
export const createCustomerAndSubscription = createAsyncThunk(
  "paypal/createCustomerAndSubscription",
  async (
    { plan_id, currency, payerId, paymentId, customerEmail },
    thunkAPI
  ) => {
    const { csrfToken } = thunkAPI.getState().auth;

    try {
      if (plan_id === "P-Free-Plan") {
        const response = await axiosInstance.post(
          "https://memorydiaries.com/lifestripe/create-subscription",
          {
            plan_id: "P-Free-Plan",
            currency,
            payerId,
            paymentId,
            customerEmail,
          },
          {
            headers: {
              "csrf-token": csrfToken,
            },
            withCredentials: true,
          }
        );

        // Return free plan subscription response
        return response.data;
      } else {
        // Paid subscription request
        const response = await axiosInstance.post(
          "https://memorydiaries.com/lifestripe/create-subscription",
          {
            plan_id,
            currency,
            payerId,
            paymentId,
            customerEmail,
          },
          {
            headers: {
              "csrf-token": csrfToken,
            },
            withCredentials: true,
          }
        );

        if (response.status !== 200) {
          return thunkAPI.rejectWithValue(
            response.data.error || "Failed to create PayPal subscription"
          );
        }

        alert("PayPal subscription created successfully!");
        return response.data; // Contains subscriptionId and clientSecret
      }
    } catch (error) {
      if (error.response && error.response.data) {
        // Server responded with an error
        alert("Failed to create PayPal subscription");
        return thunkAPI.rejectWithValue(
          error.response.data.error || "Server error"
        );
      } else {
        // Network error or other issues
        alert("Failed to create PayPal subscription");
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// Thunk to check PayPal payment status
export const checkPayPalPaymentStatus = createAsyncThunk(
  "paypal/checkPayPalPaymentStatus",
  async (_, thunkAPI) => {
    console.log("Checking PayPal payment status...");
    const { csrfToken } = thunkAPI.getState().auth;
    try {
      const response = await axiosInstance.get(
        `https://memorydiaries.com/lifestripe/check-paypal-status`,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      console.log("PayPal payment response status:", response.status);
      if (response.status !== 200) {
        return thunkAPI.rejectWithValue(
          response.data.error || "Failed to check PayPal payment status"
        );
      }
      console.log("PayPal payment status checked successfully!", response.data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        console.log("Failed to check PayPal payment status");
        return thunkAPI.rejectWithValue(
          error.response.data.error || "Server error"
        );
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const deleteCustomerAndSubscription = createAsyncThunk(
  "paypal/deleteCustomerAndSubscription",
  async ({ subscriptionId }, thunkAPI) => {
    console.log("Deleting PayPal subscription...");
    const { csrfToken } = thunkAPI.getState().auth;
    try {
      const response = await axiosInstance.delete(
        `https://memorydiaries.com/lifestripe/delete-subscription`,
        { subscriptionId },
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      console.log("PayPal subscription deleted successfully!", response.data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        console.log("Failed to delete PayPal subscription");
        return thunkAPI.rejectWithValue(
          error.response.data.error || "Server error"
        );
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// Thunk for updating PayPal subscription
export const updatePayPalSubscription = createAsyncThunk(
  "paypal/updatePayPalSubscription",
  async ({ subscriptionId, selectedPlan, currency }, thunkAPI) => {
    const { csrfToken } = thunkAPI.getState().auth;

    try {
      const response = await axiosInstance.post(
        "https://memorydiaries.com/lifestripe/update-plan",
        {
          subscriptionId,
          selectedPlan,
          currency,
        },
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );

      if (response.status !== 200) {
        return thunkAPI.rejectWithValue(
          response.data.error || "Failed to update PayPal subscription"
        );
      }

      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return thunkAPI.rejectWithValue(
          error.response.data.error || "Server error"
        );
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const initialState = {
  paypalState: {},
  loading: false,
  error: null,
  isPaid: null,
  subscriptionId: null,
  approveUrl: null,
};

const stripeSlice = createSlice({
  name: "stripe",
  initialState,
  reducers: {
    updatePayPalSubscriptionId: (state, action) => {
      state.subscriptionId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCustomerAndSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCustomerAndSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.payerId = action.payload.payerId;
        state.paymentId = action.payload.paymentId;
        state.isPaid = action.payload.isPaid;
        state.planId = action.payload.planId;
        console.log(
          "PayPal subscription created successfully! isPaid:",
          state.isPaid
        );
      })
      .addCase(createCustomerAndSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Something went wrong";
        console.log("Failed to create PayPal subscription");
      })
      .addCase(updatePayPalSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePayPalSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.planId = action.payload.planId;
        console.log(
          "PayPal subscription updated successfully!",
          action.payload
        );
      })
      .addCase(updatePayPalSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to update PayPal subscription";
        console.log("Failed to update PayPal subscription");
      })
      .addCase(checkPayPalPaymentStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(checkPayPalPaymentStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.isPaid = action.payload.isPaid;
        state.planId = action.payload.planId;
        state.subscriptionId = action.payload.subscriptionId;
        console.log(
          "PayPal payment status checked successfully!",
          action.payload
        );
      })
      .addCase(checkPayPalPaymentStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to check PayPal payment status";
        console.log("Failed to check PayPal payment status");
      })
      .addCase(deleteCustomerAndSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCustomerAndSubscription.fulfilled, (state, action) => {
        state.loading = false;
        console.log(
          "PayPal subscription deleted successfully!",
          action.payload
        );
      })
      .addCase(deleteCustomerAndSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to delete PayPal subscription";
        console.log("Failed to delete PayPal subscription");
      })
      .addCase(clearAllData.fulfilled, (state) => {
        return { ...initialState };
      });
  },
});

export const selectPayPalState = (state) => {
  return {
    loading: state.stripe.loading,
    error: state.stripe.error,
    isPaid: state.stripe.isPaid,
  };
};

export const { updatePayPalSubscriptionId } = stripeSlice.actions;

export default stripeSlice.reducer;
