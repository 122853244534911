import { combineReducers } from "redux";
import authSliceReducer from "./authSlice";
import myDocumentsReducer from "./MyDocumentSlice";
import familySliceReducer from "./FamilySlice";
import stripeSliceReducer from "./stripeSlice";
import fetchBackendDataReducer from "./fetchBackendData";
import dynamicStoriesReducer from "./DynamicStorySlice";
import pageConfigSliceReducer from "./pageConfigSlice";
import voiceReducer from "./voiceSlice";
import dynamicPhotoReducer from "./DynamicPhotoSlice";
import dynamicVideoReducer from "./DynamicVideoSlice";
import familyVoiceReducer from "./familyVoiceSlice";

const appReducer = combineReducers({
  myDocuments: myDocumentsReducer,
  auth: authSliceReducer,
  family: familySliceReducer,
  stripe: stripeSliceReducer,
  data: fetchBackendDataReducer,
  dynamicStories: dynamicStoriesReducer,
  pageConfig: pageConfigSliceReducer,
  selectedVoice: voiceReducer,
  dynamicPhotos: dynamicPhotoReducer,
  dynamicVideos: dynamicVideoReducer,
  familyVoice: familyVoiceReducer,
});

const rootReducer = (state, action) => {
  console.log("Action Type:", action.type);
  if (action.type === "user/clearAllData/fulfilled") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
