// src/store/voiceSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { fetchBackendData } from "./fetchBackendData";
import { clearAllData } from "./fetchBackendData";
import logger from "../utils/logger";

const initialState = {
  familyVoice: [],
};

const familyVoiceSlice = createSlice({
  name: "familyVoice",
  initialState,
  reducers: {
    saveSelectedFamilyVoice: (state, action) => {
      logger.debug(
        `saveSelectedFamilyVoice: ${JSON.stringify(action.payload)}`
      );
      const { familyId, familyVoice } = action.payload;
      // Find index of the object with matching familyId
      const existingIndex = state.familyVoice.findIndex(
        (voice) => voice.familyId === familyId
      );
      if (existingIndex !== -1) {
        // Update existing object if familyId exists
        state.familyVoice[existingIndex] = { familyId, familyVoice };
        logger.debug("Voice updated in state");
      } else {
        // Add new object if familyId does not exist
        state.familyVoice.push({ familyId, familyVoice });
        logger.debug("Voice added to state");
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBackendData.fulfilled, (state, action) => {
        state.familyVoice = action.payload?.state?.familyVoice || [];
      })
      .addCase(clearAllData.fulfilled, (state) => {
        return { ...initialState };
      });
  },
});

export const { saveSelectedFamilyVoice } = familyVoiceSlice.actions;
export default familyVoiceSlice.reducer;
