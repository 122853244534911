import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../components/axiosConfig";

export const clearAllData = createAsyncThunk(
  "data/clearAllData",
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(clearData());
    return true; // Indicate success
  }
);

export const fetchBackendData = createAsyncThunk(
  "data/fetchBackendData",
  async (_, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    try {
      const response = await axiosInstance.get(
        `https://memorydiaries.com/lifeData/getState`,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );

      console.log("Backend response:", response.data); // Add logging
      return response.data; // Return state object from response.data
    } catch (error) {
      console.log("Fetch failed:", error); // Add logging
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const deleteBackendData = createAsyncThunk(
  "data/deleteBackendData",
  async (_, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    try {
      const response = await axiosInstance.delete(
        `https://memorydiaries.com/lifeData/deleteState`,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      console.log("Backend response:", response.data); // Add logging
      return response.data; // Return state object from response.data
    } catch (error) {
      console.log("Delete failed:", error); // Add logging
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const initialState = {
  data: {},
  status: "idle",
  error: null,
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    clearData: (state) => {
      return { ...initialState }; // Reset data to initial state
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBackendData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBackendData.fulfilled, (state, action) => {
        state.status = "succeeded";
        const payload = action.payload || {};
        console.log("Payload:", payload);
        state.data = payload;
      })
      .addCase(fetchBackendData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Unknown error";
        alert("Fetch failed with error:" + JSON.stringify(action.payload)); // Add logging
      })
      .addCase(clearAllData.fulfilled, () => {
        return { ...initialState }; // Reset state to initial state
      });
  },
});

export const { clearData } = dataSlice.actions;

export default dataSlice.reducer;
