import React from "react";
import { Modal, Box, IconButton, Typography, Button } from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";
import Webcam from "react-webcam";

const ShowVideoRecord = ({
  open,
  handleClose,
  isRecording,
  handleRecordStart,
  handleRecordStop,
  webcamRef,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="record-video-modal"
      aria-describedby="record-video-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxWidth: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography style={{ fontFamily: "'Days One', sans-serif" }}>
          Record Video
        </Typography>

        {isRecording ? (
          <Box sx={{ position: "relative" }}>
            <Webcam
              audio={false}
              ref={webcamRef}
              style={{ width: "100%", height: "auto", borderRadius: "10px" }}
            />
            <IconButton
              onClick={handleRecordStop}
              sx={{
                position: "absolute",
                top: "calc(100% - 100px - 5px)",
                left: "50%",
                transform: "translate(-50%, 0)",
                color: "red",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                },
                "& svg": {
                  fontSize: 48,
                },
                width: 100,
                height: 100,
              }}
            >
              <VideocamIcon sx={{ fontSize: 48 }} />
            </IconButton>
          </Box>
        ) : (
          <Box sx={{ position: "relative" }}>
            <Webcam
              audio={false}
              ref={webcamRef}
              style={{ width: "100%", height: "auto", borderRadius: "10px" }}
            />
            <IconButton
              color="default"
              onClick={handleRecordStart}
              aria-label="record video"
              sx={{
                position: "absolute",
                top: "calc(100% - 100px - 5px)",
                left: "50%",
                transform: "translate(-50%, 0)",
                color: "white",
                width: 100,
                height: 100,
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                },
                "& svg": {
                  fontSize: 48,
                },
              }}
            >
              <VideocamIcon />
            </IconButton>
          </Box>
        )}
        <Button
          onClick={handleRecordStop}
          style={{
            margin: "16px 0",
            borderColor: "#E29578",
            backgroundColor: "#E29578",
            "&:hover": {
              backgroundColor: "#FFDDD2",
            },
            fontFamily: "'Days One', sans-serif",
            color: "white",
            padding: "5px 10px",
            borderRadius: 10,
          }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default ShowVideoRecord;
