import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemIcon,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AccountBoxIcon from "@mui/icons-material/Face";
import PhotoLibraryIcon from "@mui/icons-material/PhotoAlbum";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import EmailIcon from "@mui/icons-material/Email";
import CottageIcon from "@mui/icons-material/Cottage";
import FileCopyIcon from "@mui/icons-material/FileCopy";

const FamilyNavigationDrawer = () => {
  const [open, setOpen] = useState(false);
  const state = useSelector((state) => state.auth);
  const userName = state.user.name;
  const familyName = state.user.familyName;
  const pageConfigs = useSelector((state) => state.pageConfig.pageConfig || []);
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  const getIcon = (text) => {
    switch (text) {
      case "Home":
        return <CottageIcon sx={{ color: "white" }} />;
      case `${familyName.name}'s Profile`:
        return <AccountBoxIcon sx={{ color: "white" }} />;
      case "My Documents":
        return <FileCopyIcon sx={{ color: "white" }} />;
      case "My Photos":
        return <PhotoLibraryIcon sx={{ color: "white" }} />;
      case "My Videos":
        return <VideoLibraryIcon sx={{ color: "white" }} />;
      case "Contact Us":
        return <EmailIcon sx={{ color: "white" }} />;
      default:
        return <MenuBookIcon sx={{ color: "white" }} />;
    }
  };

  const list = () => {
    const routes = {
      Home: "/",
      [`${familyName.name}'s Profile`]: "/familyprofile",
    };

    const routesTwo = {
      [`${userName}'s Documents`]: "/familydocuments",
      "Contact Us": "/contactus",
    };

    return (
      <div
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
        style={{
          backgroundColor: "#83C5BE",
          color: "white",
          paddinTop: 20,
          paddingBottom: 20,
          height: "100vh",
        }}
      >
        <List>
          {Object.entries(routes).map(([text, route]) => (
            <ListItem key={text} component={Link} to={route}>
              <ListItemIcon>{getIcon(text)}</ListItemIcon>
              <ListItemText primary={text} sx={{ color: "white" }} />
            </ListItem>
          ))}

          {Array.isArray(pageConfigs) &&
            pageConfigs
              .filter((config) => {
                return config.pageType === "story";
              })
              .map((config) => (
                <ListItem
                  key={config.storageKey && config.pageType === "story"}
                  onClick={() =>
                    handleNavigation(`/stories/family/${config.storageKey}`)
                  }
                  sx={{ cursor: "pointer" }}
                >
                  <ListItemIcon>
                    {<MenuBookIcon sx={{ color: "white" }} />}
                  </ListItemIcon>
                  <ListItemText
                    primary={`${userName}'s ${config.pageTitle}`}
                    sx={{ color: "white" }}
                    onClick={() => {
                      handleNavigation(`/stories/family/${config.storageKey}`);
                    }}
                  />
                </ListItem>
              ))}

          {Array.isArray(pageConfigs) &&
            pageConfigs
              .filter((config) => {
                return config.pageType === "photo";
              })
              .map((config) => (
                <ListItem
                  key={config.storageKey && config.pageType === "photo"}
                  onClick={() =>
                    handleNavigation(`/photos/family/${config.storageKey}`)
                  }
                  sx={{ cursor: "pointer" }}
                >
                  <ListItemIcon>
                    {<PhotoLibraryIcon sx={{ color: "white" }} />}
                  </ListItemIcon>
                  <ListItemText
                    primary={`${userName}'s ${config.pageTitle}`}
                    sx={{ color: "white" }}
                    onClick={() => {
                      handleNavigation(`/photos/family/${config.storageKey}`);
                    }}
                  />
                </ListItem>
              ))}

          {Array.isArray(pageConfigs) &&
            pageConfigs
              .filter((config) => {
                return config.pageType === "video";
              })
              .map((config) => (
                <ListItem
                  key={config.storageKey && config.pageType === "video"}
                  onClick={() =>
                    handleNavigation(`/videos/family/${config.storageKey}`)
                  }
                  sx={{ cursor: "pointer" }}
                >
                  <ListItemIcon>
                    {<VideoLibraryIcon sx={{ color: "white" }} />}
                  </ListItemIcon>
                  <ListItemText
                    primary={`${userName}'s ${config.pageTitle}`}
                    sx={{ color: "white" }}
                    onClick={() => {
                      handleNavigation(`/videos/family/${config.storageKey}`);
                    }}
                  />
                </ListItem>
              ))}

          {Object.entries(routesTwo).map(([text, route]) => (
            <ListItem key={text} component={Link} to={route}>
              <ListItemIcon>{getIcon(text)}</ListItemIcon>
              <ListItemText primary={text} sx={{ color: "white" }} />
            </ListItem>
          ))}
        </List>
      </div>
    );
  };

  return (
    <div>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </div>
  );
};

export default FamilyNavigationDrawer;
