// ShowPhotoRecord.js
import React from "react";
import { Modal, Box, IconButton, Typography, Button } from "@mui/material";
import Webcam from "react-webcam";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

const ShowPhotoRecord = ({ open, handleClose, handleTakePhoto, webcamRef }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="take-photo-modal"
      aria-describedby="take-photo-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxWidth: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography style={{ fontFamily: "'Days One', sans-serif" }}>
          Record Photo
        </Typography>

        <Box sx={{ position: "relative", mt: 2 }}>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            style={{ width: "100%", height: "auto", borderRadius: "10px" }}
          />
          <IconButton
            onClick={handleTakePhoto}
            sx={{
              position: "absolute",
              top: "calc(100% - 100px - 5px)",
              left: "50%",
              transform: "translate(-50%, 0)",
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.7)",
              },
              "& svg": {
                fontSize: 48,
              },
              width: 100,
              height: 100,
            }}
          >
            <CameraAltIcon sx={{ fontSize: 40 }} />
          </IconButton>
        </Box>
        <Button
          onClick={handleClose}
          style={{
            margin: "16px 0",
            borderColor: "#E29578",
            backgroundColor: "#E29578",
            "&:hover": {
              backgroundColor: "#FFDDD2",
            },
            fontFamily: "'Days One', sans-serif",
            color: "white",
            padding: "5px 10px",
            borderRadius: 10,
          }}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

export default ShowPhotoRecord;
