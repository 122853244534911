import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "./axiosConfig";
import { useSelector, useDispatch } from "react-redux";
import {
  CircularProgress,
  Typography,
  Button,
  Radio,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Box,
  Card,
  CardContent,
  Grid,
  Snackbar,
} from "@mui/material";
import { logout } from "../store/authSlice";
import { clearAllData } from "../store/fetchBackendData";
import {
  updatePayPalSubscription,
  deleteCustomerAndSubscription,
} from "../store/stripeSlice";
import logger from "../utils/logger";

const UpdatePayment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const csrfToken = useSelector((state) => state.auth.csrfToken);
  const currentSubscriptionId = useSelector(
    (state) => state.stripe.subscriptionId
  );
  const planId = useSelector((state) => state.stripe.planId);
  const [subscriptionId, setSubscriptionId] = useState(currentSubscriptionId);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(planId);
  const [approveUrl, setApproveUrl] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const availablePlans = [
    { id: "P-Free-Plan", name: "Free Plan", amount: 0 },
    {
      id: "P-27A05978RC4239114M4VOXMY",
      name: "Monthly Subscription",
      amount: 12.99,
    },
    {
      id: "P-91X40889A7040131WM4VUNEY",
      name: "Six-Monthly Subscription",
      amount: 55.99,
    },
    {
      id: "P-3MN67890N6614404PM4VPUOI",
      name: "Yearly Subscription",
      amount: 99.99,
    },
  ];

  const getPlanNameById = (planId) => {
    const plan = availablePlans.find((p) => p.id === planId);
    return plan ? plan.name : null;
  };

  const planName = getPlanNameById(planId);

  useEffect(() => {
    logger.info("Current selected plan", selectedPlan);
  }, [selectedPlan]);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      logger.debug(`Current subscription PlanId: ${planId}`);
    }
  }, [refresh]);

  useEffect(() => {
    const fetchPaymentInfo = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          "https://memorydiaries.com/lifestripe/payment-info",
          {
            headers: { "csrf-token": csrfToken },
            withCredentials: true,
          }
        );
        logger.debug("Payment info response", response);
        const { payerId, paymentId, planId } = response?.data || {};
        if (!paymentId || !payerId || !planId) {
          setError("No active subscription found.");
        } else {
          setSubscriptionId(paymentId);
          setSelectedPlan(planId);
          logger.info("Payment info retrieved successfully");
        }
      } catch (err) {
        setError("Failed to retrieve payment info. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    if (csrfToken) {
      fetchPaymentInfo();
    }
  }, [csrfToken]);

  const handlePlanChange = (event) => setSelectedPlan(event.target.value);

  const handlePlanChangeSubmit = async () => {
    if (!subscriptionId || !selectedPlan) {
      setError("Please select a plan.");
      return;
    }

    setLoading(true);

    try {
      const result = await dispatch(
        updatePayPalSubscription({
          subscriptionId: subscriptionId,
          selectedPlan,
          currency: "USD",
        })
      );
      logger.info("update paypal subscription dispatched");

      if (result.meta.requestStatus === "fulfilled") {
        const { approveUrl } = result.payload;
        if (approveUrl) {
          setApproveUrl(approveUrl);
          setMessage(
            "Re-consent required. Please approve the subscription update."
          );
        } else {
          setMessage("Plan updated successfully!");
          setError("");
          setRefresh(true);
        }
      } else {
        setError("Failed to update the subscription.");
      }
    } catch (error) {
      setError("An error occurred while updating the plan.");
    } finally {
      setLoading(false);
      navigate("/profile");
    }
  };

  const handleRedirectToApproval = () => {
    if (approveUrl) {
      window.location.href = approveUrl;
    }
  };

  const handleDeleteSubscription = async (subscriptionId) => {
    alert.warning(
      "Are you sure you want to delete your paypal subscription? You will be logged out."
    );
    setLoading(true);
    try {
      await dispatch(clearAllData());
      logger.info("Clear all data dispatched");
      await dispatch(deleteCustomerAndSubscription(subscriptionId));
      logger.info("Delete subscription dispatched");
      await dispatch(logout());
      logger.info("Logout dispatched");
      navigate("/");
    } catch (error) {
      setError("Failed to delete the subscription.");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateCardDetails = async () => {
    logger.info("inside updating card details");
    try {
      const subscriptionId = currentSubscriptionId;

      if (subscriptionId) {
        logger.info(`Subscription ID found:, ${subscriptionId}`);
        const paypalPopup = window.open(
          `https://www.paypal.com/billing/subscriptions/update?subscription_id=${subscriptionId}`,
          "paypalUpdateWindow",
          "width=500,height=700"
        );

        if (!paypalPopup) {
          console.error("Popup blocked or failed to open.");
        }
      } else {
        console.error("Subscription ID not found.");
      }
    } catch (error) {
      console.error("Error fetching subscription details: ", error);
    } finally {
      navigate("/profile");
    }
  };

  const handleCancel = () => navigate("/profile");

  const handleCloseSnackbar = () => {
    setError("");
    setMessage("");
  };

  return (
    <Box sx={{ maxWidth: 800, mx: "auto", mt: 4, p: 3 }}>
      <Card variant="outlined">
        <CardContent>
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            style={{
              fontFamily: "'Days One', sans-serif",
              fontStyle: "italic",
              marginBottom: 20,
            }}
          >
            Update Payment Method
          </Typography>
          {loading ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <Box>
              {approveUrl ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleRedirectToApproval}
                  fullWidth
                  style={{ marginBottom: 16 }}
                >
                  Approve Subscription Update
                </Button>
              ) : (
                <>
                  <Typography
                    variant="h5"
                    align="center"
                    sx={{
                      mb: 4,
                      fontWeight: "bold",
                      fontFamily: "'Days One', sans-serif",
                    }}
                  >
                    Current Plan: {planName}
                  </Typography>
                  <FormControl component="fieldset" fullWidth>
                    <Typography
                      variant="body1"
                      sx={{ mb: 2, fontFamily: "'Days One', sans-serif" }}
                    >
                      Choose a Plan:
                    </Typography>
                    <RadioGroup
                      value={selectedPlan}
                      onChange={handlePlanChange}
                    >
                      {availablePlans.map((plan) => (
                        <FormControlLabel
                          key={plan.id}
                          value={plan.id}
                          control={<Radio />}
                          label={`${plan.name} - $${plan.amount}`}
                          style={{
                            fontFamily: "Verdana, sans-serif",
                          }}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>

                  <Grid container spacing={2} sx={{ mt: 4 }}>
                    <Grid item xs={12} sm={6}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handlePlanChangeSubmit}
                        disabled={loading}
                        style={{
                          fontFamily: "'Days One', sans-serif",
                          backgroundColor: "#E29578",
                          color: "white",
                          borderRadius: 10,
                        }}
                      >
                        Update Plan
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        onClick={handleDeleteSubscription(subscriptionId)}
                        style={{
                          fontFamily: "'Days One', sans-serif",
                          backgroundColor: "#E29578",
                          color: "white",
                          borderRadius: 10,
                        }}
                      >
                        Delete Subscription
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleUpdateCardDetails}
                        style={{
                          fontFamily: "'Days One', sans-serif",
                          backgroundColor: "#E29578",
                          color: "white",
                          borderRadius: 10,
                        }}
                      >
                        Update Card Details
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        onClick={handleCancel}
                        style={{
                          fontFamily: "'Days One', sans-serif",
                          backgroundColor: "#E29578",
                          color: "white",
                          borderRadius: 10,
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          )}
        </CardContent>
      </Card>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={error}
      />
      <Snackbar
        open={!!message}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={message}
      />
    </Box>
  );
};

export default UpdatePayment;
