import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { registerUser, nodeMail } from "../store/authSlice";
import { useNavigate } from "react-router-dom";
import safeRegex from "safe-regex";
import DOMPurify from "dompurify";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axiosInstance from "./axiosConfig";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { fetchFamilyMembers } from "../store/FamilySlice";
import logger from "../utils/logger";

// eslint-disable-next-line
function Register({ open, onClose, onRegisterSuccess }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [dob, setDob] = useState("");
  // eslint-disable-next-line
  const [captchaToken, setCaptchaToken] = useState(null);
  const dispatch = useDispatch();
  const authStatus = useSelector((state) => state.auth.status);
  const authError = useSelector((state) => state.auth.error);
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    generateUniquePassword();
  }, []);

  const generateCode = () => {
    let genCode = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 6; i++) {
      genCode += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return genCode;
  };

  const isUniqueCode = async (code) => {
    try {
      const response = await axiosInstance.post(
        "https://memorydiaries.com/lifeauth/check-code-unique",
        { code },
        { withCredentials: true }
      );
      logger.debug("Checking code uniqueness:", response.data.isUnique);
      return response.data.isUnique;
    } catch (error) {
      logger.error("Error checking code uniqueness:", error);
      return false;
    }
  };

  const generateUniquePassword = async () => {
    let unique = false;
    let newPassword;

    while (!unique) {
      newPassword = generateCode();
      unique = await isUniqueCode(newPassword);
    }

    setCode(newPassword);
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const nameRegex = /^[a-zA-Z0-9\s]{1,50}$/;
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,}$/;

  const handleRegister = async () => {
    const sanitizedEmail = DOMPurify.sanitize(email);
    const sanitizedName = DOMPurify.sanitize(name);
    const sanitizedPassword = DOMPurify.sanitize(password);
    const sanitizedConfirmPassword = DOMPurify.sanitize(confirmPassword);

    if (!safeRegex(emailRegex) || !emailRegex.test(sanitizedEmail)) {
      alert("Invalid email format");
      return;
    }

    if (!safeRegex(nameRegex) || !nameRegex.test(sanitizedName)) {
      alert(
        "Invalid name format. Only alphanumeric characters and spaces are allowed."
      );
      return;
    }

    if (!safeRegex(passwordRegex) || !passwordRegex.test(sanitizedPassword)) {
      alert(
        "Password must be at least 4 characters long, contain at least one number, one lowercase and one uppercase letter."
      );
      return;
    }

    if (!dob) {
      alert("Please enter a valid date of birth.");
      return;
    }

    if (sanitizedPassword !== sanitizedConfirmPassword) {
      alert("Passwords do not match");
      return;
    }

    if (!executeRecaptcha) {
      logger.error("Recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("login");
    setCaptchaToken(token);

    if (!token) {
      alert("Please complete the reCAPTCHA.");
      return;
    }

    const result = await dispatch(
      registerUser({
        email: sanitizedEmail,
        password: sanitizedPassword,
        name: sanitizedName,
        code: code,
        dob: dob,
        captchaToken: token,
      })
    );
    logger.info("Register user dispatched");
    await fetchFamilyMembers();
    logger.info("Family members fetched");

    try {
      logger.info(`Sending welcome email to: ${sanitizedEmail}`);
      await dispatch(
        nodeMail({
          subject: "Welcome",
          to_email: sanitizedEmail,
          name: sanitizedName,
          password: sanitizedPassword, // Optional if not needed
          code: "",
        })
      ).unwrap(); // Unwrap to catch errors in try-catch
    } catch (error) {
      logger.error("Failed to send email:", error);
    }

    if (registerUser.fulfilled.match(result) && result.payload?.name) {
      onRegisterSuccess(result.payload.name);
      onClose();
    } else {
      logger.error("Unable to access userName from payload");
    }
  };

  const handleForgotPassword = () => {
    navigate("/reset-password");
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        style={{
          fontFamily: "'Days One', sans-serif",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Register
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter your registration details.
        </DialogContentText>
        {authError && (
          <DialogContentText style={{ color: "red" }}>
            {authError}
          </DialogContentText>
        )}
        <TextField
          autoFocus
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          value={name}
          onChange={(e) => setName(DOMPurify.sanitize(e.target.value))}
          style={{ fontFamily: "Verdana, sans-serif" }}
        />
        <TextField
          margin="dense"
          label="Email"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(DOMPurify.sanitize(e.target.value))}
          style={{ fontFamily: "Verdana, sans-serif" }}
        />
        <TextField
          data-testid="pass1"
          margin="dense"
          label="Password"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(DOMPurify.sanitize(e.target.value))}
          style={{ fontFamily: "Verdana, sans-serif" }}
        />
        <TextField
          data-testid="pass2"
          margin="dense"
          label="Confirm Password"
          type="password"
          fullWidth
          value={confirmPassword}
          onChange={(e) =>
            setConfirmPassword(DOMPurify.sanitize(e.target.value))
          }
          style={{ fontFamily: "Verdana, sans-serif" }}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date of Birth"
            value={dob}
            onChange={(newValue) => setDob(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Cancel
        </Button>
        <Button
          data-testid="reg"
          onClick={handleRegister}
          color="primary"
          disabled={authStatus === "loading"}
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Register
        </Button>
        <Button
          onClick={handleForgotPassword}
          color="secondary"
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Forgot Password?
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Register;
