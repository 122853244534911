// src/components/NewPageForm.js
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addPageConfig } from "../store/pageConfigSlice";
import {
  TextField,
  Button,
  Box,
  DialogTitle,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const NewPageForm = ({ closeModal }) => {
  const [pageTitle, setPageTitle] = useState("");
  const [storyLabel, setStoryLabel] = useState("");
  const [storageKey, setStorageKey] = useState("");
  const [pageType, setPageType] = useState("");
  const existingKeys = useSelector((state) =>
    Array.isArray(state.pageConfig.pageConfig)
      ? state.pageConfig.pageConfig.map((config) => config.storageKey)
      : []
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleTitleChange = (e) => {
    const input = e.target.value.slice(0, 20); // limit to 20 characters
    const capitalizedInput = input
      .replace(/\b\w/g, (char) => char.toUpperCase()) // capitalize each word
      .replace(/\s+/g, " "); // normalize spaces
    setPageTitle(capitalizedInput);
  };

  const handleStoryChange = (e) => {
    const input = e.target.value.slice(0, 20); // limit to 20 characters
    const capitalizedInput = input
      .replace(/\b\w/g, (char) => char.toUpperCase()) // capitalize each word
      .replace(/\s+/g, " "); // normalize spaces
    setStoryLabel(capitalizedInput);
  };

  const handleTypeChange = (e) => {
    setPageType(e.target.value); // Handle type selection
  };

  useEffect(() => {
    const generatedKey = pageTitle
      .toLowerCase()
      .replace(/\s+/g, "-") // replace spaces with hyphens
      .replace(/[^a-z0-9-]/g, ""); // remove special characters
    setStorageKey(generatedKey);
  }, [pageTitle]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (existingKeys.includes(storageKey)) {
      alert(
        "A page with this title already exists. Please choose a different title."
      );
      return;
    }

    dispatch(
      addPageConfig({
        pageTitle,
        storyLabel,
        storageKey,
        pageType,
      })
    );
    setPageTitle("");
    setStoryLabel("");
    setStorageKey("");
    setPageType("");
    closeModal();
    const handleRoute = () => {
      switch (pageType) {
        case "story":
          navigate(`/stories/${storageKey}`);
          break;
        case "photo":
          navigate(`/photos/${storageKey}`);
          break;
        case "video":
          navigate(`/videos/${storageKey}`);
          break;
        default:
          break;
      }
    };
    handleRoute();
  };

  const handleCancel = () => {
    closeModal();
    navigate("/profile"); // Navigate to the desired route, e.g., "/pages"
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        p: 3,
        borderRadius: 2,
        boxShadow: 3,
        backgroundColor: "background.paper",
        width: { xs: "100%", sm: "400px" },
        mx: "auto",
      }}
    >
      <DialogTitle
        style={{
          fontFamily: "'Days One', sans-serif",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Add a new section
      </DialogTitle>

      <DialogContent sx={{ mt: 2 }}>
        <TextField
          label="Page Title"
          value={pageTitle}
          onChange={handleTitleChange}
          fullWidth
          required
        />
        <TextField
          label="Story Label"
          value={storyLabel}
          onChange={handleStoryChange}
          fullWidth
          required
        />

        <FormControl fullWidth required sx={{ mt: 2 }}>
          <InputLabel>Page Type</InputLabel>
          <Select
            value={pageType}
            onChange={handleTypeChange}
            label="Page Type"
          >
            <MenuItem value="story">Story</MenuItem>
            <MenuItem value="photo">Photo</MenuItem>
            <MenuItem value="video">Video</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>

      <DialogActions
        sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
      >
        <Button
          type="submit"
          variant="contained"
          sx={{
            fontFamily: "'Days One', sans-serif",
            backgroundColor: "#E29578",
            color: "white",
            "&:hover": { backgroundColor: "#D18368" },
            borderRadius: 1,
            px: 3,
          }}
        >
          Add Page
        </Button>
        <Button
          variant="outlined"
          onClick={handleCancel}
          sx={{
            fontFamily: "'Days One', sans-serif",
            borderColor: "#E29578",
            color: "#E29578",
            "&:hover": { backgroundColor: "#F9E4DE", borderColor: "#D18368" },
            borderRadius: 1,
            px: 3,
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Box>
  );
};

export default NewPageForm;
