import React from "react";
import { Button, Container, Typography, Box } from "@mui/material";

const AboutUs = ({ onClose }) => {
  const handleBack = () => {
    onClose();
  };

  const buttonStyle = {
    fontSize: "18px",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#E29578",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    borderRadius: 10,
  };

  const buttonHoverStyle = {
    backgroundColor: "#FFDDD2",
  };

  return (
    <Container maxWidth="md" style={{ padding: "20px", lineHeight: "1.6" }}>
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        style={{
          fontFamily: "'Days One', sans-serif",
          fontWeight: "bold",
          marginBottom: "20px",
        }}
      >
        About Us
      </Typography>

      <Box mb={4}>
        <Typography variant="body1" paragraph style={{ fontFamily: "Verdana" }}>
          <strong>Welcome to Memory Diaries!</strong> We are a small company
          focused on bringing families and generations together through
          technology. We prioritize privacy and cybersecurity.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Our Focus: Bringing Families Together Through Technology
        </Typography>
        <Typography variant="body1" paragraph style={{ fontFamily: "Verdana" }}>
          We believe in the power of technology to improve connections within
          families. In today’s fast-paced society, people can sometimes feel
          disconnected, and we aim to change that by offering a platform where
          memories, stories, and family moments can be easily shared and
          preserved.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Our Ethical Approach
        </Typography>
        <Typography variant="body1" paragraph style={{ fontFamily: "Verdana" }}>
          Our team consists of individuals who are passionate about maintaining
          a healthy, balanced lifestyle. We are dedicated to providing a
          high-quality product that not only strengthens family connections but
          also contributes to your mental well-being by encouraging reflection
          and mindful sharing.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Our Commitment to Customer Satisfaction
        </Typography>
        <Typography variant="body1" paragraph style={{ fontFamily: "Verdana" }}>
          At Memory Diaries, customer satisfaction is a top priority. We are
          committed to delivering an excellent product and exceptional customer
          service. If you have any questions or concerns, don’t hesitate to
          reach out to our friendly support team. We’re here to assist you and
          ensure that you have the best possible experience with our platform.
        </Typography>
      </Box>

      <Button
        onClick={handleBack}
        variant="contained"
        style={{
          ...buttonStyle,
          width: "48%",
          fontFamily: "'Days One', sans-serif",
        }}
        onMouseOver={(e) =>
          (e.currentTarget.style.backgroundColor =
            buttonHoverStyle.backgroundColor)
        }
        onMouseOut={(e) =>
          (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor)
        }
        onFocus={(e) =>
          (e.currentTarget.style.backgroundColor =
            buttonHoverStyle.backgroundColor)
        }
        onBlur={(e) =>
          (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor)
        }
      >
        Back
      </Button>
    </Container>
  );
};

export default AboutUs;
