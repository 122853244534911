import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addFamilyMember } from "../store/FamilySlice";
import TextField from "@mui/material/TextField";
import DOMPurify from "dompurify";
import safeRegex from "safe-regex";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import logger from "../utils/logger";
import Typography from "@mui/material/Typography";

function AddFamily() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [relation, setRelation] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dob, setDob] = useState("");

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneNumberRegex = /^\d+$/;

  const handleSubmit = (e) => {
    e.preventDefault();

    const sanitizedInputs = {
      name: DOMPurify.sanitize(name),
      relation: DOMPurify.sanitize(relation),
      email: DOMPurify.sanitize(email),
      phoneNumber: DOMPurify.sanitize(phoneNumber),
    };

    if (
      !sanitizedInputs.name ||
      !sanitizedInputs.relation ||
      !sanitizedInputs.email
    ) {
      alert("All fields are required.");
      return;
    }

    if (!safeRegex(emailRegex) || !emailRegex.test(sanitizedInputs.email)) {
      alert("Please enter a valid email.");
      return;
    }

    if (sanitizedInputs.phoneNumber) {
      if (
        !safeRegex(phoneNumberRegex) ||
        !phoneNumberRegex.test(sanitizedInputs.phoneNumber)
      ) {
        alert("Please enter a valid phone number with only numerical values.");
        return;
      }
    }

    const familyMemberData = {
      ...sanitizedInputs,
      dob: dob ? dob : null,
    };

    dispatch(addFamilyMember(familyMemberData)).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        logger.info("Family member added successfully.");
        navigate("/profile");
      }
    });
  };

  const handleCancel = () => {
    navigate("/profile");
  };

  const buttonStyle = {
    fontSize: "18px",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#E29578",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    borderRadius: 10,
  };

  const buttonHoverStyle = {
    backgroundColor: "#FFDDD2",
  };

  return (
    <div
      style={{
        padding: "20px",
        maxWidth: "600px",
        marginTop: "50px",
        margin: "auto",
        fontSize: "18px",
        backgroundColor: "#f9f9f9",
        borderRadius: "10px",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        style={{
          fontFamily: "'Days One', sans-serif",
          fontStyle: "italic",
          marginBottom: 20,
        }}
      >
        Add Family Member
      </Typography>
      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", gap: "20px" }}
      >
        <div>
          <label
            style={{ fontWeight: "bold", fontFamily: "'Days One', sans-serif" }}
          >
            Name:
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(DOMPurify.sanitize(e.target.value))}
            style={{
              fontSize: "16px",
              padding: "10px",
              width: "100%",
              borderRadius: "10px",
              border: "1px solid #ccc",
              fontFamily: "Verdana, sans-serif",
            }}
            placeholder="Enter name"
            required
          />
        </div>
        <div>
          <label
            style={{ fontWeight: "bold", fontFamily: "'Days One', sans-serif" }}
          >
            Relation:
          </label>
          <input
            type="text"
            value={relation}
            onChange={(e) => setRelation(DOMPurify.sanitize(e.target.value))}
            style={{
              fontSize: "16px",
              padding: "10px",
              width: "100%",
              borderRadius: "10px",
              border: "1px solid #ccc",
              fontFamily: "Verdana, sans-serif",
            }}
            placeholder="Enter relation"
            required
          />
        </div>
        <div>
          <label
            style={{ fontWeight: "bold", fontFamily: "'Days One', sans-serif" }}
          >
            Email:
          </label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(DOMPurify.sanitize(e.target.value))}
            style={{
              fontSize: "16px",
              padding: "10px",
              width: "100%",
              borderRadius: "10px",
              border: "1px solid #ccc",
              fontFamily: "Verdana, sans-serif",
            }}
            placeholder="Enter email"
            required
          />
        </div>
        <div>
          <label
            style={{ fontWeight: "bold", fontFamily: "'Days One', sans-serif" }}
          >
            Phone Number (Optional):
          </label>
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(DOMPurify.sanitize(e.target.value))}
            style={{
              fontSize: "16px",
              padding: "10px",
              width: "100%",
              borderRadius: "10px",
              border: "1px solid #ccc",
              fontFamily: "Verdana, sans-serif",
            }}
            placeholder="Enter phone number"
          />
        </div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date of Birth (Optional)"
            value={dob}
            onChange={(newValue) => setDob(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            type="submit"
            style={{
              ...buttonStyle,
              width: "48%",
              fontFamily: "'Days One', sans-serif",
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor =
                buttonHoverStyle.backgroundColor)
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor =
                buttonStyle.backgroundColor)
            }
            onFocus={(e) =>
              (e.currentTarget.style.backgroundColor =
                buttonHoverStyle.backgroundColor)
            }
            onBlur={(e) =>
              (e.currentTarget.style.backgroundColor =
                buttonStyle.backgroundColor)
            }
          >
            Add Family
          </button>
          <button
            type="button"
            onClick={handleCancel}
            style={{
              ...buttonStyle,
              width: "48%",
              fontFamily: "'Days One', sans-serif",
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor =
                buttonHoverStyle.backgroundColor)
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.backgroundColor =
                buttonStyle.backgroundColor)
            }
            onFocus={(e) =>
              (e.currentTarget.style.backgroundColor =
                buttonHoverStyle.backgroundColor)
            }
            onBlur={(e) =>
              (e.currentTarget.style.backgroundColor =
                buttonStyle.backgroundColor)
            }
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddFamily;
