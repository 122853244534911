import React from "react";
import {
  Button,
  Container,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { green } from "@mui/material/colors";

const Affiliate = ({ onClose }) => {
  const handleBack = () => {
    onClose();
  };

  const faqs = [
    {
      question: "How does the affiliate program work?",
      answer:
        "Contact us via the email address provided. Once approved, you'll receive a unique affiliate link to share with your audiences. You'll earn a commission for every purchase made via your link. Use your blog, social media platforms, YouTube channel, or any creative content to promote it. We provide marketing materials and ideas to help you get started.",
    },
    {
      question: "Who can participate in the affiliate program?",
      answer:
        "Anyone who believes in the importance of bringing families together and sharing important memories.",
    },
  ];

  const buttonStyle = {
    fontSize: "18px",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#E29578",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    borderRadius: 10,
  };

  const buttonHoverStyle = {
    backgroundColor: "#FFDDD2",
  };

  return (
    <Container maxWidth="md" style={{ padding: "20px", lineHeight: "1.6" }}>
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        style={{
          fontFamily: "'Days One', sans-serif",
          fontWeight: "bold",
          marginBottom: "20px",
        }}
      >
        Become an Affiliate
      </Typography>

      <Box mb={4}>
        <Typography variant="body1" paragraph style={{ fontFamily: "Verdana" }}>
          Welcome to the Memory Diaries Affiliate Program! As a member of our
          affiliate family, you will share the wisdom of connecting family,
          while earning rewards for each referral. Together, let’s create a
          world where we bridge the connections between generations and improve
          family connections.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif", marginBottom: 20 }}
        >
          Affiliate Benefits
        </Typography>
        <Typography
          variant="body1"
          paragraph
          style={{
            display: "flex",
            alignItems: "center",
            fontFamily: "Verdana",
          }}
        >
          <CheckCircleIcon
            style={{
              color: green[500],
              marginRight: "8px",
            }}
          />
          Commission of 10% on subscriptions
        </Typography>
        <Typography
          variant="body1"
          paragraph
          style={{
            display: "flex",
            alignItems: "center",
            fontFamily: "Verdana",
          }}
        >
          <CheckCircleIcon style={{ color: green[500], marginRight: "8px" }} />A
          unique affiliate link upon signup to promote our brand
        </Typography>
        <Typography
          variant="body1"
          paragraph
          style={{
            display: "flex",
            alignItems: "center",
            fontFamily: "Verdana",
          }}
        >
          <CheckCircleIcon style={{ color: green[500], marginRight: "8px" }} />
          Get paid automatically through PayPal
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          style={{
            fontFamily: "'Days One', sans-serif",
            marginBottom: 20,
          }}
        >
          Affiliate Program FAQ's
        </Typography>

        {faqs.map((faq, index) => (
          <Accordion key={index} elevation={1}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-${index}-content`}
              id={`faq-${index}-header`}
            >
              <Typography
                variant="body1"
                style={{ fontWeight: "bold", fontFamily: "Verdana" }}
              >
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" style={{ fontFamily: "Verdana" }}>
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>

      <Box mb={4}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          style={{
            fontFamily: "'Days One', sans-serif",
            marginBottom: 20,
          }}
        >
          Contact Us
        </Typography>
        <Typography variant="body1" paragraph style={{ fontFamily: "Verdana" }}>
          If you have any questions or concerns, please contact us at:{" "}
          <Link href="mailto:dr.dbrown@icloud.com">
            <strong>dr.dbrown@icloud.com</strong>
          </Link>
        </Typography>
      </Box>

      <Button
        onClick={handleBack}
        variant="contained"
        style={{
          ...buttonStyle,
          width: "48%",
          fontFamily: "'Days One', sans-serif",
        }}
        onMouseOver={(e) =>
          (e.currentTarget.style.backgroundColor =
            buttonHoverStyle.backgroundColor)
        }
        onMouseOut={(e) =>
          (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor)
        }
        onFocus={(e) =>
          (e.currentTarget.style.backgroundColor =
            buttonHoverStyle.backgroundColor)
        }
        onBlur={(e) =>
          (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor)
        }
        aria-label="Go back"
      >
        Back
      </Button>
    </Container>
  );
};

export default Affiliate;
