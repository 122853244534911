import React from "react";
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ShowPhotoModal = ({ selectedPhoto, handleClosePhotoModal }) => {
  return (
    <Dialog
      open={Boolean(selectedPhoto)}
      onClose={handleClosePhotoModal}
      maxWidth="md"
    >
      <DialogTitle style={{ fontFamily: "'Days One', sans-serif" }}>
        Photo
        <IconButton
          aria-label="close"
          onClick={handleClosePhotoModal}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {selectedPhoto && (
          <img
            src={`data:${selectedPhoto.contentType};base64,${selectedPhoto.base64}`}
            alt="Selected"
            style={{ width: "100%", height: "auto" }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClosePhotoModal}
          color="success"
          style={{
            margin: "16px 0",
            borderColor: "#E29578",
            backgroundColor: "#E29578",
            "&:hover": {
              backgroundColor: "#FFDDD2",
            },
            fontFamily: "'Days One', sans-serif",
            color: "white",
            padding: "5px 10px",
            borderRadius: 10,
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShowPhotoModal;
