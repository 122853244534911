import React from "react";
import { Typography, Container } from "@mui/material";
import Contactus from "../Img/ContactUs.jpg";
import Facebook from "@mui/icons-material/Facebook";
import Pinterest from "@mui/icons-material/Pinterest";
import X from "@mui/icons-material/X";
import Reddit from "@mui/icons-material/Reddit";
import WhatsApp from "@mui/icons-material/WhatsApp";

const ContactUs = () => {
  const socialLinks = {
    Facebook:
      "https://www.facebook.com/sharer/sharer.php?u=https://memorydiaries.com",
    Pinterest:
      "https://pinterest.com/pin/create/button/?url=https://memorydiaries.com",
    X: "https://twitter.com/intent/tweet?url=https://memorydiaries.com",
    Reddit: "https://www.reddit.com/submit?url=https://memorydiaries.com",
    WhatsApp: "https://api.whatsapp.com/send?text=https://memorydiaries.com",
  };

  return (
    <Container style={{ marginTop: "10%" }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{ flexDirection: "column", width: "100%", marginRight: "10%" }}
        >
          <Typography
            variant="h4"
            component="h1"
            style={{
              fontFamily: "'Days One', sans-serif",
              marginBottom: 50,
            }}
          >
            Contact Us
          </Typography>
          <Typography
            variant="h6"
            style={{ fontFamily: "Verdana, sans-serif" }}
          >
            We welcome your feedback, so that we can continue to improve our
            products. Please feel free to reach out to us with any questions,
            comments, or suggestions. You can contact us via email at:
            <a href="mailto:dr.dbrown@icloud.com">dr.dbrown@icloud.com</a>
          </Typography>
          <div style={footerIconsStyle}>
            <a
              href={socialLinks.Facebook}
              target="_blank"
              rel="noopener noreferrer"
              title="Facebook"
            >
              <Facebook style={{ color: footerIconsStyle.color }} />
            </a>
            <a
              href={socialLinks.Pinterest}
              target="_blank"
              rel="noopener noreferrer"
              title="Pinterest"
            >
              <Pinterest style={{ color: footerIconsStyle.color }} />
            </a>
            <a
              href={socialLinks.X}
              target="_blank"
              rel="noopener noreferrer"
              title="X (Twitter)"
            >
              <X style={{ color: footerIconsStyle.color }} />
            </a>
            <a
              href={socialLinks.Reddit}
              target="_blank"
              rel="noopener noreferrer"
              title="Reddit"
            >
              <Reddit style={{ color: footerIconsStyle.color }} />
            </a>
            <a
              href={socialLinks.WhatsApp}
              target="_blank"
              rel="noopener noreferrer"
              title="WhatsApp"
            >
              <WhatsApp style={{ color: footerIconsStyle.color }} />
            </a>
          </div>
        </div>

        <div style={{ flexDirection: "column" }}>
          <img
            src={Contactus}
            alt="Contact Us"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      </div>
    </Container>
  );
};

const footerIconsStyle = {
  display: "flex",
  alignItems: "center",
  gap: "15px",
  marginTop: "40px",
  color: "#006D77",
};

export default ContactUs;
