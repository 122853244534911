import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateDetails, clearError } from "../store/authSlice";
import TextField from "@mui/material/TextField";
import DOMPurify from "dompurify";
import imageCompression from "browser-image-compression";
import axiosInstance from "./axiosConfig";
import { Button, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import logger from "../utils/logger";

const phoneRegex = /^[0-9]{10,15}$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

function EditProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, loading, error } = useSelector((state) => state.auth);

  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || "");
  const [email, setEmail] = useState(user?.email || "");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [profileImage, setProfileImage] = useState(user?.profileImage || "");
  const [address, setAddress] = useState(user?.address || "");
  const [dob, setDob] = useState(user?.dob ? new Date(user.dob) : null);
  // eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState("");
  // eslint-disable-next-line
  const [selectedFile, setSelectedFile] = useState(null);
  const { csrfToken } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user) {
      setPhoneNumber(user.phoneNumber || "");
      setEmail(user.email || "");
      setProfileImage(user.profileImage || "");
      setAddress(user.address || "");
    }
  }, [user]);

  useEffect(() => {
    getProfilePhoto();
  }, []);

  const getProfilePhoto = async () => {
    try {
      const response = await axiosInstance.get(
        "https://memorydiaries.com/lifeauth/profile-get",
        {
          method: "GET",
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        const result = response.data;
        logger.debug("Profile image:", result);
        logger.info("Profile image retrieved successfully");
        setProfileImage(URL.createObjectURL(result));
      } else {
        const error = await response.json();
        logger.error("Get image error:", error);
        alert("Failed to get image");
      }
    } catch (error) {
      logger.error("Error getting the image:", error);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await axiosInstance.delete(
        "https://memorydiaries.com/lifeauth/profile-delete",
        {
          method: "DELETE",
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setProfileImage(null);
        logger.info("Image deleted successfully");
      } else {
        const error = await response.json();
        logger.error("Delete error:", error);
        alert("Failed to delete image");
      }
    } catch (error) {
      logger.error("Error deleting the image:", error);
      alert("Error deleting the image");
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];

    logger.debug("Preparing to upload selected file:", file);
    const supportedFormats = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/tiff",
      "image/bmp",
      "image/webp",
      "image/svg+xml",
      "image/jpg",
    ];

    if (!file) {
      alert("No file selected.");
      return;
    }
    if (!supportedFormats.includes(file.type)) {
      setErrorMessage(
        "Supported formats are: JPG, JPEG, PNG, GIF, TIFF, BMP, WebP, SVG"
      );
      return;
    }
    setErrorMessage("");

    const options = {
      maxWidthOrHeight: 400,
      useWebWorker: true,
      fileType: "image/jpg",
    };
    try {
      const compressedFile = await imageCompression(file, options);
      const renamedFile = new File([compressedFile], file.name, {
        type: compressedFile.type,
        lastModified: Date.now(),
      });
      logger.debug("Compressed file:", renamedFile);
      setSelectedFile(renamedFile);

      const formData = new FormData();
      formData.append("file", renamedFile);
      const response = await axiosInstance.post(
        "https://memorydiaries.com/lifeauth/upload-profile-image",
        formData,
        {
          method: "POST",
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        const result = response.data;
        logger.debug("Upload response:", result);
        logger.info("Image uploaded successfully");
        setProfileImage(URL.createObjectURL(renamedFile));
      } else {
        const error = await response.json();
        logger.error("Upload error:", error);
        setErrorMessage("Failed to upload image");
      }
    } catch (error) {
      logger.error("Error compressing the image:", error);
      setErrorMessage("Error processing the image");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (phoneNumber && !phoneRegex.test(phoneNumber)) {
      alert(
        "Invalid phone number. Please enter a valid number with 10 to 15 digits."
      );
      return;
    }

    if (email && !emailRegex.test(email)) {
      alert("Invalid email address.");
      return;
    }

    if (newPassword && !passwordRegex.test(newPassword)) {
      alert(
        "New password must be at least 8 characters long and include at least one letter and one number."
      );
      return;
    }

    if (newPassword && newPassword !== confirmNewPassword) {
      alert("New password and confirm password do not match.");
      return;
    }

    const sanitizedPhoneNumber = DOMPurify.sanitize(phoneNumber);
    const sanitizedEmail = DOMPurify.sanitize(email);
    const sanitizedOldPassword = DOMPurify.sanitize(oldPassword);
    const sanitizedNewPassword = DOMPurify.sanitize(newPassword);
    const sanitizedAddress = DOMPurify.sanitize(address);

    const updateData = {};
    if (sanitizedPhoneNumber) updateData.phoneNumber = sanitizedPhoneNumber;
    if (sanitizedEmail) updateData.email = sanitizedEmail;
    if (sanitizedNewPassword) updateData.newPassword = sanitizedNewPassword;
    if (sanitizedOldPassword) updateData.currentPassword = sanitizedOldPassword;
    if (sanitizedAddress) updateData.address = sanitizedAddress;
    updateData.dob = dob ? dob : null;

    dispatch(updateDetails(updateData)).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        logger.info("Profile update dispatched successfully");
        navigate("/profile");
      }
    });
  };

  const handleCancel = () => {
    navigate("/profile");
  };

  return (
    <div
      style={{
        padding: "20px",
        maxWidth: "600px",
        margin: "auto",
        fontSize: "18px",
        backgroundColor: "#f9f9f9",
        borderRadius: "10px",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        style={{
          fontFamily: "'Days One', sans-serif",
          fontStyle: "italic",
          marginBottom: 20,
        }}
      >
        Edit Profile
      </Typography>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          fontFamily: "Verdana, sans-serif",
        }}
      >
        <div>
          <label
            style={{
              fontWeight: "bold",
              fontFamily: "'Days One', sans-serif",
            }}
          >
            Phone Number:
          </label>
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            style={{
              fontSize: "16px",
              padding: "10px",
              width: "100%",
              borderRadius: "5px",
              border: "1px solid #ccc",
              fontFamily: "Verdana, sans-serif",
            }}
          />
        </div>
        <div>
          <label
            style={{ fontWeight: "bold", fontFamily: "'Days One', sans-serif" }}
          >
            Email Address:
          </label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              fontSize: "16px",
              padding: "10px",
              width: "100%",
              borderRadius: "5px",
              border: "1px solid #ccc",
              fontFamily: "Verdana, sans-serif",
            }}
            required
          />
        </div>
        <div>
          <label
            style={{ fontWeight: "bold", fontFamily: "'Days One', sans-serif" }}
          >
            Old Password (Optional):
          </label>
          <input
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            style={{
              fontSize: "16px",
              padding: "10px",
              width: "100%",
              borderRadius: "5px",
              border: "1px solid #ccc",
              fontFamily: "Verdana, sans-serif",
            }}
          />
        </div>
        <div>
          <label
            style={{ fontWeight: "bold", fontFamily: "'Days One', sans-serif" }}
          >
            New Password (Optional):
          </label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            style={{
              fontSize: "16px",
              padding: "8px",
              width: "100%",
              fontFamily: "Verdana, sans-serif",
            }}
          />
        </div>
        <div>
          <label
            style={{ fontWeight: "bold", fontFamily: "'Days One', sans-serif" }}
          >
            Confirm New Password (Optional):
          </label>
          <input
            type="password"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            style={{
              fontSize: "16px",
              padding: "10px",
              width: "100%",
              borderRadius: "5px",
              border: "1px solid #ccc",
              fontFamily: "Verdana, sans-serif",
            }}
          />
        </div>
        <div>
          <label
            style={{
              fontWeight: "bold",
              marginRight: "20px",
              fontFamily: "'Days One', sans-serif",
            }}
          >
            Profile Image:
          </label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={{
              fontSize: "16px",
              padding: "5px",
              width: "100%",
              borderRadius: "10px",
              display: "none",
              color: "white",
            }}
            id="file-upload"
          />
          <label htmlFor="file-upload">
            <Button
              variant="outlined"
              component="span"
              sx={{
                margin: "16px 0",
                borderColor: "#E29578",
                backgroundColor: "#E29578",
                "&:hover": {
                  backgroundColor: "#FFDDD2",
                },
                fontFamily: "'Days One', sans-serif",
                color: "white",
                borderRadius: 10,
              }}
            >
              Choose Photo
            </Button>
          </label>

          {profileImage && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={profileImage}
                alt="Profile"
                style={{
                  marginTop: "10px",
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                  borderRadius: "5px",
                }}
              />
              <Button
                onClick={handleDelete}
                style={{
                  padding: "5px 10px",
                  border: "none",
                  backgroundColor: "#f44336",
                  "&:hover": {
                    backgroundColor: "#FFDDD2",
                  },
                  marginLeft: "20px",
                  color: "white",
                  cursor: "pointer",
                  borderRadius: 10,
                  fontFamily: "'Days One', sans-serif",
                }}
              >
                Delete
              </Button>
            </div>
          )}
        </div>
        <div>
          <label
            style={{ fontWeight: "bold", fontFamily: "'Days One', sans-serif" }}
          >
            Postal Address:
          </label>
          <textarea
            value={address}
            onChange={(e) => setAddress(DOMPurify.sanitize(e.target.value))}
            style={{
              fontSize: "16px",
              padding: "10px",
              width: "100%",
              height: "100px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              fontFamily: "Verdana, sans-serif",
            }}
          />
        </div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date of Birth"
            value={dob}
            onChange={(newValue) => setDob(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>

        {error && (
          <div style={{ color: "red", textAlign: "center" }}>
            <p>{error}</p>
            <button
              type="button"
              onClick={() => dispatch(clearError())}
              style={{ fontFamily: "'Days One', sans-serif" }}
            >
              Clear Error
            </button>
          </div>
        )}
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            type="submit"
            style={{
              fontSize: "18px",
              padding: "10px 20px",
              backgroundColor: "#E29578",
              color: "white",
              borderRadius: 10,
              fontFamily: "'Days One', sans-serif",
            }}
            disabled={loading}
          >
            {loading ? "Saving..." : "Save"}
          </Button>
          <Button
            type="button"
            onClick={handleCancel}
            style={{
              fontSize: "18px",
              padding: "10px 20px",
              backgroundColor: "#E29578",
              color: "white",
              borderRadius: 10,
              fontFamily: "'Days One', sans-serif",
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
}

export default EditProfile;
