import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Balance from "../Img/Balance.webp";
import Privacy from "../Img/Privacy.webp";
import Connection from "../Img/Connection.webp";

const postsData = [
  {
    id: 1,
    slug: "how-technology-can-help-families-connect",
    title: "How Technology Can Help Families Connect",
    content: (
      <div>
        <p>
          In a fast-paced world, staying connected with family can be
          challenging. This post explores how technology can bring us closer.
          Here are some ways technology helps:
        </p>
        <span
          style={{
            display: "block",
            marginLeft: "40px",
            marginRight: "40px",
            marginBottom: "20px",
          }}
        >
          <b>1. Video Calls:</b> We aim to add this functionality to Memory
          Diaries. Platforms like Zoom, Apple FaceTime, and Google Meet make it
          easy to stay connected with family members, even if they're miles
          away. You can host virtual reunions, celebrate birthdays, or just
          catch up with loved ones at any time.
        </span>
        <span
          style={{
            display: "block",
            marginLeft: "40px",
            marginRight: "40px",
            marginBottom: "20px",
          }}
        >
          <b>2. Shared Photo Albums:</b> Apps like Memory Diaries, Google Photos
          allow family members to create and share photo albums, making it easy
          to relive cherished memories and stay updated with each other's lives.
        </span>
        <span
          style={{
            display: "block",
            marginLeft: "40px",
            marginRight: "40px",
            marginBottom: "20px",
          }}
        >
          <b>3. Family Group Chats:</b> We aim to add this functionality to
          Memory Diaries. Messaging apps like WhatsApp and Telegram let families
          create private group chats where they can share updates, photos, and
          videos in real-time.
        </span>
        <span
          style={{
            display: "block",
            marginLeft: "40px",
            marginRight: "40px",
            marginBottom: "20px",
          }}
        >
          <b>4. Virtual Board Games and Activities:</b> We aim to add this
          functionality to Memory Diaries. Websites and apps like Jackbox Games,
          Kahoot, and Houseparty offer engaging games and activities that
          families can enjoy together online.
        </span>
        <span
          style={{
            display: "block",
            marginLeft: "40px",
            marginRight: "40px",
            marginBottom: "20px",
          }}
        >
          <b>5. Technology for Elderly Members:</b> Tech assistance includes
          website accessibility features and tablets with large screens
          specifically designed for elderly family members, making it easier for
          them to participate in virtual family activities.
        </span>
        <p>
          Technology has made it easier than ever to bridge the gap between
          generations, ensuring families remain close no matter where life takes
          them.
        </p>
      </div>
    ),
    date: "November 15, 2024",
    image: Connection,
  },
  {
    id: 2,
    slug: "importance-of-privacy-in-digital-age",
    title: "The Importance of Privacy in the Digital Age",
    content: (
      <div>
        <p>
          Privacy is more important than ever in today's digital age. We discuss
          how we ensure privacy in our services and the steps families can take
          to safeguard their data.
        </p>
        <span
          style={{
            display: "block",
            marginLeft: "40px",
            marginRight: "40px",
            marginBottom: "20px",
          }}
        >
          <b>1. Data Encryption:</b> All your personal data, including stories
          and photos, is encrypted at rest and in transit, ensuring that
          unauthorized parties cannot access it.
        </span>
        <span
          style={{
            display: "block",
            marginLeft: "40px",
            marginRight: "40px",
            marginBottom: "20px",
          }}
        >
          <b>2. No Data Sharing:</b> Unlike many platforms, we commit to not
          sharing or selling your personal data with third parties. Your
          information remains your own.
        </span>
        <span
          style={{
            display: "block",
            marginLeft: "40px",
            marginRight: "40px",
            marginBottom: "20px",
          }}
        >
          <b>3. Tips for Families:</b> Families should educate themselves on
          privacy best practices, such as using strong, unique passwords,
          enabling multi-factor authentication (MFA), and being cautious about
          sharing sensitive information online.
        </span>
        <span
          style={{
            display: "block",
            marginLeft: "40px",
            marginRight: "40px",
            marginBottom: "20px",
          }}
        >
          <b>4. Parental Controls:</b> Parents can monitor and limit their
          children’s online activities to ensure their safety and privacy. Use
          tools that restrict access to inappropriate content and help manage
          screen time.
        </span>
        <p>
          Protecting your privacy is a shared responsibility. By staying
          informed and using trusted platforms, you can ensure your personal
          data remains safe and secure.
        </p>
      </div>
    ),
    date: "October 15, 2024",
    image: Privacy,
  },
  {
    id: 3,
    slug: "importance-of-privacy-in-digital-age",
    title: "Balancing Mental Health with Technology Use",
    content: (
      <div>
        <p>
          Mental health is a growing concern. Learn how you can balance your
          mental health while using technology to stay connected, including tips
          for managing screen time and setting boundaries.
        </p>
        <span
          style={{
            display: "block",
            marginLeft: "40px",
            marginRight: "40px",
            marginBottom: "20px",
          }}
        >
          <b>1. Set Screen Time Limits:</b> Use apps or device settings to set
          daily screen time limits. This helps ensure you spend more time on
          meaningful activities and less time scrolling through social media.
        </span>
        <span
          style={{
            display: "block",
            marginLeft: "40px",
            marginRight: "40px",
            marginBottom: "20px",
          }}
        >
          <b>2. Designate Tech-Free Zones:</b> Make certain areas of your home,
          such as the dining room or bedroom, tech-free zones to encourage
          face-to-face interactions and improve sleep quality.
        </span>
        <span
          style={{
            display: "block",
            marginLeft: "40px",
            marginRight: "40px",
            marginBottom: "20px",
          }}
        >
          <b>3. Practice Mindful Usage:</b> Before using your devices, ask
          yourself if it's for productivity, connection, or relaxation. Avoid
          mindless scrolling and focus on intentional activities.
        </span>
        <span
          style={{
            display: "block",
            marginLeft: "40px",
            marginRight: "40px",
            marginBottom: "20px",
          }}
        >
          <b>4. Use Technology for Mental Health:</b> Apps like Memory Diaries,
          Headspace, and BetterHelp provide tools for relaxation, meditation,
          and connecting with therapists online.
        </span>
        <span
          style={{
            display: "block",
            marginLeft: "40px",
            marginRight: "40px",
            marginBottom: "20px",
          }}
        >
          <b>5. Connect with Nature:</b> Spend time outdoors and engage in
          activities that don’t involve screens. Balancing technology with time
          in nature can significantly boost your mental well-being.
        </span>
        <span
          style={{
            display: "block",
            marginLeft: "40px",
            marginRight: "40px",
            marginBottom: "20px",
          }}
        >
          <b>6. Encourage Open Discussions:</b> Talk to your family about how
          technology affects mental health. Share tips and hold each other
          accountable for creating a healthy balance.
        </span>
        <p>
          Technology is a powerful tool, but it’s essential to use it wisely. By
          establishing healthy habits and maintaining balance, you can enjoy its
          benefits without compromising your mental well-being.
        </p>
      </div>
    ),
    date: "September 10, 2024",
    image: Balance,
  },
];

const Blog = ({ onClose }) => {
  const { slug } = useParams();
  const post = postsData.find((p) => p.slug === slug);

  const [selectedPost, setSelectedPost] = useState(null);
  const [open, setOpen] = useState(false);

  // Sort posts by date (most recent first)
  const sortedPosts = [...postsData].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  const openModal = (post) => {
    setSelectedPost(post);
    setOpen(true);
  };

  const closeModal = () => {
    setSelectedPost(null);
    setOpen(false);
  };

  const buttonStyle = {
    fontSize: "18px",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#E29578",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    borderRadius: 10,
  };

  const buttonHoverStyle = {
    backgroundColor: "#FFDDD2",
  };

  if (slug && !post) {
    return <div>Post not found.</div>;
  }

  const extractTextFromJSX = (jsx) => {
    if (typeof jsx === "string") {
      return jsx; // If it's a string, return it as-is
    }

    if (Array.isArray(jsx)) {
      return jsx.map(extractTextFromJSX).join("");
    }

    if (jsx && typeof jsx === "object" && jsx.props) {
      return extractTextFromJSX(jsx.props.children);
    }

    return "";
  };

  return (
    <div style={{ padding: "20px" }}>
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        style={{
          fontFamily: "'Days One', sans-serif",
          fontStyle: "italic",
          marginBottom: "100px",
        }}
      >
        Memory Diaries Blog
      </Typography>
      <Grid container spacing={4}>
        {sortedPosts.map((post) => (
          <Grid item xs={12} sm={6} md={4} key={post.id}>
            <Card
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                borderRadius: "10px",
              }}
            >
              <CardMedia
                component="img"
                height="180"
                image={post.image}
                alt={post.title}
                style={{
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              />
              <CardContent
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: "bold",
                    fontFamily: "'Days One', sans-serif",
                  }}
                >
                  {post.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {post.date}
                </Typography>
                <Typography variant="body1" style={{ fontFamily: "Verdana" }}>
                  {extractTextFromJSX(postsData[0].content).length > 100
                    ? `${extractTextFromJSX(postsData[0].content).slice(
                        0,
                        100
                      )}...`
                    : extractTextFromJSX(postsData[0].content)}
                </Typography>
                <Button
                  onClick={() => openModal(post)}
                  variant="contained"
                  style={{
                    ...buttonStyle,
                    width: "100%",
                    fontFamily: "'Days One', sans-serif",
                  }}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      buttonHoverStyle.backgroundColor)
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      buttonStyle.backgroundColor)
                  }
                  aria-label="Read more"
                >
                  Read More
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Button
        onClick={onClose}
        variant="contained"
        style={{
          ...buttonStyle,
          width: "50%",
          marginTop: 50,
          fontFamily: "'Days One', sans-serif",
        }}
        onMouseOver={(e) =>
          (e.currentTarget.style.backgroundColor =
            buttonHoverStyle.backgroundColor)
        }
        onMouseOut={(e) =>
          (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor)
        }
        aria-label="go back"
      >
        Back
      </Button>

      <Dialog
        open={open}
        onClose={closeModal}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            padding: "20px",
            backgroundColor: "#fefefe",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        {selectedPost && (
          <>
            <DialogTitle
              style={{
                fontFamily: "'Days One', sans-serif",
                fontSize: "1.8rem",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              {selectedPost.title}
            </DialogTitle>
            <DialogContent>
              <img
                src={selectedPost.image}
                alt={selectedPost.title}
                style={{
                  display: "block",
                  width: "60%",
                  margin: "0 auto",
                  height: "auto",
                  borderRadius: "12px",
                  marginBottom: "20px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                }}
              />
              <Typography
                variant="body2"
                color="textSecondary"
                style={{
                  fontFamily: "'Verdana', sans-serif",
                  fontSize: "0.9rem",
                  marginBottom: "20px",
                  textAlign: "center",
                  marginTop: 50,
                }}
              >
                {selectedPost.date}
              </Typography>
              <Typography
                variant="body1"
                style={{
                  fontFamily: "'Verdana', sans-serif",
                  lineHeight: "1.6",
                  fontSize: "1rem",
                  color: "#333",
                }}
              >
                {selectedPost.content}
              </Typography>
            </DialogContent>
            <DialogActions
              style={{ justifyContent: "center", marginTop: "20px" }}
            >
              <Button
                onClick={closeModal}
                style={{
                  ...buttonStyle,
                  fontFamily: "'Days One', sans-serif",
                  padding: "10px 20px",
                  borderRadius: "20px",
                  textTransform: "none",
                }}
              >
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default Blog;
