import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateFamilyDetails } from "../../store/FamilySlice";
import DOMPurify from "dompurify";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import logger from "../../utils/logger";
import Typography from "@mui/material/Typography";

const phoneRegex = /^[0-9]{10,15}$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function FamilyEditProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { familyName } = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.auth.loading);

  const [phoneNumber, setPhoneNumber] = useState(familyName?.phoneNumber || "");
  const [email, setEmail] = useState(familyName?.email || "");
  const [address, setAddress] = useState(familyName?.postalAddress || "");
  const [dob, setDob] = useState(
    familyName?.dob ? new Date(familyName?.dob) : null
  );

  useEffect(() => {
    if (familyName) {
      setPhoneNumber(familyName.phoneNumber || null);
      setEmail(familyName.email || "");
      setAddress(familyName.postalAddress || null);
    }
  }, [familyName]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (phoneNumber && !phoneRegex.test(phoneNumber)) {
      alert(
        "Invalid phone number. Please enter a valid number with 10 to 15 digits."
      );
      return;
    }

    if (!email || !emailRegex.test(email)) {
      alert("Invalid email address.");
      return;
    }

    const sanitizedPhoneNumber = DOMPurify.sanitize(phoneNumber);
    const sanitizedEmail = DOMPurify.sanitize(email);
    const sanitizedAddress = DOMPurify.sanitize(address);

    const updateData = {
      familyId: familyName.familyId,
      phoneNumber: sanitizedPhoneNumber,
      email: sanitizedEmail,
      postalAddress: sanitizedAddress,
      dob: dob ? dob : null,
    };

    dispatch(updateFamilyDetails(updateData)).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        navigate("/familyprofile");
        logger.info("Family member profile updated successfully.");
      }
    });
  };

  const handleCancel = () => {
    navigate("/familyprofile");
  };

  return (
    <div
      style={{
        padding: "20px",
        maxWidth: "600px",
        margin: "auto",
        fontSize: "18px",
        backgroundColor: "#f9f9f9",
        borderRadius: "10px",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        style={{
          fontFamily: "'Days One', sans-serif",
          fontStyle: "italic",
          marginBottom: 20,
        }}
      >
        Edit Family Member Profile
      </Typography>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          fontFamily: "Verdana, sans-serif",
        }}
      >
        <div>
          <label
            style={{ fontWeight: "bold", fontFamily: "Verdana, sans-serif" }}
          >
            Phone Number (Optional):
          </label>
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            style={{
              fontSize: "16px",
              padding: "10px",
              width: "100%",
              borderRadius: "5px",
              border: "1px solid #ccc",
              fontFamily: "Verdana, sans-serif",
            }}
          />
        </div>
        <div>
          <label
            style={{ fontWeight: "bold", fontFamily: "Verdana, sans-serif" }}
          >
            Email Address:
          </label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              fontSize: "16px",
              padding: "10px",
              width: "100%",
              borderRadius: "5px",
              border: "1px solid #ccc",
              fontFamily: "Verdana, sans-serif",
            }}
            required
          />
        </div>
        <div>
          <label
            style={{ fontWeight: "bold", fontFamily: "Verdana, sans-serif" }}
          >
            Postal Address (Optional):
          </label>
          <textarea
            value={address}
            onChange={(e) => setAddress(DOMPurify.sanitize(e.target.value))}
            style={{
              fontSize: "16px",
              padding: "10px",
              width: "100%",
              height: "100px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              fontFamily: "Verdana, sans-serif",
            }}
          />
        </div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date of Birth (Optional)"
            value={dob}
            onChange={(newValue) => setDob(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            type="submit"
            style={{
              fontSize: "18px",
              padding: "10px 20px",
              backgroundColor: "#E29578",
              color: "white",
              borderRadius: 10,
              fontFamily: "'Days One', sans-serif",
            }}
            disabled={loading}
          >
            {loading ? "Saving..." : "Save"}
          </Button>
          <Button
            type="button"
            onClick={handleCancel}
            style={{
              fontSize: "18px",
              padding: "10px 20px",
              backgroundColor: "#E29578",
              color: "white",
              borderRadius: 10,
              fontFamily: "'Days One', sans-serif",
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
}

export default FamilyEditProfile;
