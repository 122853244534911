// src/store/dynamicStoriesSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { fetchBackendData } from "./fetchBackendData";
import { v4 as uuidv4 } from "uuid";
import { clearAllData } from "./fetchBackendData";
import logger from "../utils/logger";

const initialState = {
  dynamicStories: {},
};

const dynamicStoriesSlice = createSlice({
  name: "dynamicStories",
  initialState,
  reducers: {
    addStory: (state, action) => {
      const { storageKey, text } = action.payload;
      if (!state[storageKey]) {
        state[storageKey] = { stories: [] };
      }
      const newStory = {
        id: uuidv4(),
        text,
        dateCreated: new Date().toISOString(),
        lastModified: new Date().toISOString(),
      };
      state[storageKey].stories.push(newStory);
    },
    editStory: (state, action) => {
      const { storageKey, id, text } = action.payload;
      const story = state[storageKey]?.stories.find((story) => story.id === id);
      if (story) {
        story.text = text;
        story.lastModified = new Date().toISOString();
      }
    },
    deleteStory: (state, action) => {
      const { storageKey, id } = action.payload;
      if (state[storageKey]) {
        state[storageKey].stories = state[storageKey].stories.filter(
          (story) => story.id !== id
        );
      }
    },
    deleteStoriesByStorageKey: (state, action) => {
      const { storageKey } = action.payload;
      logger.info("StorageKey:", storageKey);
      logger.info("Available keys in dynamicStories:", Object.keys(state));

      if (!state[storageKey]) {
        logger.warn(`Key "${storageKey}" not found in dynamicStories`);
        return state; // No changes
      }

      if (!Object.prototype.hasOwnProperty.call(state, storageKey)) {
        logger.warn(`Key "${storageKey}" not found in dynamicStories.`);
        return state; // Return the state unchanged
      }
      const { [storageKey]: _, ...updatedState } = state;

      return updatedState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBackendData.fulfilled, (state, action) => {
        const fetchedStories = action.payload?.state?.dynamicStories || {};
        Object.keys(fetchedStories).forEach((key) => {
          state[key] = fetchedStories[key];
        });
      })
      .addCase(clearAllData.fulfilled, () => {
        return { ...initialState };
      });
  },
});

export const { addStory, editStory, deleteStory, deleteStoriesByStorageKey } =
  dynamicStoriesSlice.actions;

export default dynamicStoriesSlice.reducer;
