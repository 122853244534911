// src/store/pageConfigSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { clearAllData } from "./fetchBackendData";
import { fetchBackendData } from "./fetchBackendData";
import logger from "../utils/logger";

const initialState = {
  pageConfig: [
    {
      pageTitle: "Stories",
      storyLabel: "Stories",
      storageKey: "stories",
      pageType: "story",
    },
    {
      pageTitle: "Photos",
      storyLabel: "Photos",
      storageKey: "photos",
      pageType: "photo",
    },
    {
      pageTitle: "Videos",
      storyLabel: "Videos",
      storageKey: "videos",
      pageType: "video",
    },
  ],
};

export const pageConfigSlice = createSlice({
  name: "pageConfig",
  initialState,
  reducers: {
    addPageConfig: (state, action) => {
      logger.debug("state.pageConfig:", state.pageConfig);
      logger.debug("action.payload:", action.payload);
      state.pageConfig = Array.isArray(state.pageConfig)
        ? [...state.pageConfig, action.payload]
        : [action.payload];
    },
    editPageConfig: (state, action) => {
      const { storageKey, newConfig } = action.payload;
      const index = state.pageConfig.findIndex(
        (config) => config.storageKey === storageKey
      );
      if (index !== -1) {
        state.pageConfig[index] = { ...state.pageConfig[index], ...newConfig }; // merge existing config with new values
      }
    },
    deletePageConfig: (state, action) => {
      const { storageKey } = action.payload;
      logger.info(`State.pageConfig: ${JSON.stringify(state.pageConfig)}`);
      logger.info(`StorageKey: ${storageKey}`);

      if (!Array.isArray(state.pageConfig)) {
        logger.warn(
          `pageConfig is not an array. Resetting to an empty array. Current value: ${JSON.stringify(
            state.pageConfig
          )}`
        );
      }
      logger.info("Before deletion filter");
      state.pageConfig = state.pageConfig.filter(
        (config) => config.storageKey !== storageKey
      );
      logger.info(`After delete: ${JSON.stringify(state.pageConfig)}`);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBackendData.fulfilled, (state, action) => {
        state.pageConfig = Array.isArray(action.payload?.state?.pageConfig)
          ? action.payload.state.pageConfig
          : []; // Default to an empty array if the fetched data is not an array
      })
      .addCase(clearAllData.fulfilled, () => {
        return { ...initialState };
      });
  },
});

export const { addPageConfig, editPageConfig, deletePageConfig } =
  pageConfigSlice.actions;
export default pageConfigSlice.reducer;
