import React from "react";
import { Button, Container, Typography, Box } from "@mui/material";

const SecurityPolicy = ({ onClose }) => {
  const handleBack = () => {
    onClose();
  };

  const buttonStyle = {
    fontSize: "18px",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#E29578",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    borderRadius: 10,
  };

  const buttonHoverStyle = {
    backgroundColor: "#FFDDD2",
  };

  return (
    <Container maxWidth="md" style={{ padding: "20px", lineHeight: "1.6" }}>
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        style={{
          fontFamily: "'Days One', sans-serif",
          fontWeight: "bold",
          marginBottom: "20px",
        }}
      >
        Security Policy
      </Typography>

      <Box mb={4}>
        <Typography variant="body1" paragraph style={{ fontFamily: "Verdana" }}>
          At Memory Diaries, we are committed to ensuring the security and
          privacy of your data. This Security Policy outlines how we protect
          your personal and sensitive information through encryption, secure
          practices, and industry-leading security technologies.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Data Encryption
        </Typography>
        <Typography variant="body1" paragraph style={{ fontFamily: "Verdana" }}>
          We take every possible measure to protect your data from unauthorized
          access, disclosure, alteration, or destruction. All personal and
          sensitive data is encrypted both at rest and in transit, using the
          latest advanced encryption standards. We will be transitioning to
          Post-Quantum encryption once the NIST protocols are ready to be
          implemented.
        </Typography>

        <Box ml={2}>
          <Typography
            variant="h6"
            component="h3"
            gutterBottom
            style={{ fontFamily: "'Days One', sans-serif" }}
          >
            Encryption at Rest
          </Typography>
          <Typography
            variant="body1"
            paragraph
            style={{ fontFamily: "Verdana" }}
          >
            Your data, including stories, media files, and personal information,
            is stored securely in our cloud infrastructure. We utilize advanced
            encryption algorithms such as AES-256 to ensure that your data
            remains protected even when it is stored on our servers.
          </Typography>
        </Box>

        <Box ml={2}>
          <Typography
            variant="h6"
            component="h3"
            gutterBottom
            style={{ fontFamily: "'Days One', sans-serif" }}
          >
            Encryption in Transit
          </Typography>
          <Typography
            variant="body1"
            paragraph
            style={{ fontFamily: "Verdana" }}
          >
            When data is transmitted between your device and our servers, it is
            protected using Transport Layer Security (TLS) encryption. This
            ensures that all data exchanged, such as login credentials and
            personal information, is encrypted during transmission.
          </Typography>
        </Box>
      </Box>

      <Box mb={4}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Data Privacy: We Do Not Analyze Your Personal Data
        </Typography>
        <Typography variant="body1" paragraph style={{ fontFamily: "Verdana" }}>
          Your privacy is a top priority for us. Unlike other websites, we do
          not analyze, sell, or share your personal data on Memory Diaries. We
          respect your privacy and ensure that your information remains
          confidential and protected.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Security Practices
        </Typography>
        <Typography variant="body1" paragraph style={{ fontFamily: "Verdana" }}>
          In addition to encryption, we implement industry-leading security
          practices to safeguard your data. These practices include regular
          vulnerability assessments, both static and dynamic application
          security testing, and continuous monitoring for potential threats.
        </Typography>

        <Box ml={2}>
          <Typography
            variant="h6"
            component="h3"
            gutterBottom
            style={{ fontFamily: "'Days One', sans-serif" }}
          >
            Static Application Security Testing (SAST)
          </Typography>
          <Typography
            variant="body1"
            paragraph
            style={{ fontFamily: "Verdana" }}
          >
            We use tools to analyze our source code and identify potential
            vulnerabilities during the development phase, addressing issues
            before they reach production.
          </Typography>
        </Box>

        <Box ml={2}>
          <Typography
            variant="h6"
            component="h3"
            gutterBottom
            style={{ fontFamily: "'Days One', sans-serif" }}
          >
            Dynamic Application Security Testing (DAST)
          </Typography>
          <Typography
            variant="body1"
            paragraph
            style={{ fontFamily: "Verdana" }}
          >
            Dynamic Application Security Testing simulates real-world attacks on
            the live site to proactively detect and mitigate threats.
          </Typography>
        </Box>
      </Box>

      <Box mb={4}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Partnership with Microsoft and IBM Cloud
        </Typography>
        <Typography variant="body1" paragraph style={{ fontFamily: "Verdana" }}>
          To further strengthen the security of your data, we partner with
          Microsoft and IBM Cloud. These partnerships provide us with
          state-of-the-art security infrastructure, advanced threat detection,
          and real-time incident response capabilities.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Continuous Monitoring and Incident Response
        </Typography>
        <Typography variant="body1" paragraph style={{ fontFamily: "Verdana" }}>
          We continuously monitor our infrastructure for any signs of malicious
          activity. If any suspicious activity is detected, our incident
          response team is prepared to act quickly to mitigate risks.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Compliance and Regulatory Adherence
        </Typography>
        <Typography variant="body1" paragraph style={{ fontFamily: "Verdana" }}>
          We follow best practices in cybersecurity and data protection,
          complying with standards such as <b>GDPR</b> (General Data Protection
          Regulation), which protects the personal data of EU citizens;{" "}
          <b>CCPA</b> (California Consumer Privacy Act), granting California
          residents greater control over their data; and <b>HIPAA</b> (Health
          Insurance Portability and Accountability Act), ensuring the
          confidentiality and security of health-related information. This
          ensures the confidentiality and integrity of your information.{" "}
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Your Security and Control
        </Typography>
        <Typography variant="body1" paragraph style={{ fontFamily: "Verdana" }}>
          While we implement extensive security measures to protect your data,
          you also have a role to play. Use strong, unique passwords and enable
          multi-factor authentication (MFA) wherever possible.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Contact Us
        </Typography>
        <Typography variant="body1" paragraph style={{ fontFamily: "Verdana" }}>
          If you have any questions or concerns about the security of your data
          or this Security Policy, please contact us at:{" "}
          <strong>
            <a href="mailto:dr.dbrown@icloud.com">dr.dbrown@icloud.com</a>
          </strong>
        </Typography>
      </Box>

      <Button
        onClick={handleBack}
        variant="contained"
        style={{
          ...buttonStyle,
          width: "48%",
          fontFamily: "'Days One', sans-serif",
        }}
        onMouseOver={(e) =>
          (e.currentTarget.style.backgroundColor =
            buttonHoverStyle.backgroundColor)
        }
        onMouseOut={(e) =>
          (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor)
        }
        onFocus={(e) =>
          (e.currentTarget.style.backgroundColor =
            buttonHoverStyle.backgroundColor)
        }
        onBlur={(e) =>
          (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor)
        }
        aria-label="Go back"
      >
        Back
      </Button>
    </Container>
  );
};

export default SecurityPolicy;
