// src/middleware/saveFormDataMiddleware.js
import axiosInstance from "../components/axiosConfig";
import logger from "../utils/logger";

const saveFormDataMiddleware = (store) => (next) => async (action) => {
  if (
    action.type.startsWith("data/clearAllData") ||
    action.type.endsWith("/clearData")
  ) {
    return next(action);
  }

  console.log("SaveFormDataMiddleware triggered for:", action.type);
  const result = next(action);

  const apiUrl = `https://memorydiaries.com/lifeData/saveState`;

  if (
    action.type === "pageConfig/addPageConfig" ||
    action.type === "pageConfig/editPageConfig" ||
    action.type === "pageConfig/deletePageConfig" ||
    action.type === "dynamicStories/addStory" ||
    action.type === "dynamicStories/editStory" ||
    action.type === "dynamicStories/deleteStory" ||
    action.type === "dynamicStories/deleteStoriesByStorageKey" ||
    action.type === "dynamicPhotos/editDescription" ||
    action.type === "dynamicPhotos/deleteDescription" ||
    action.type === "dynamicPhotos/deletePhotoDescriptionsByStorageKey" ||
    action.type === "dynamicVideos/editDescription" ||
    action.type === "dynamicVideos/deleteDescription" ||
    action.type === "dynamicVideos/deleteVideoDescriptionsByStorageKey" ||
    action.type === "selectedVoice/saveSelectedVoice" ||
    action.type === "familyVoice/saveSelectedFamilyVoice" ||
    action.type === "stripe/updatePayPalSubscriptionId"
  ) {
    logger.info("Dispatching action type:", action.type);
    const currentState = store.getState();
    logger.debug("Current state before saving:", currentState);
    const selectedState = {
      pageConfig: currentState.pageConfig?.pageConfig || [],
      dynamicStories: currentState.dynamicStories || {},
      dynamicPhotos: currentState.dynamicPhotos || {},
      dynamicVideos: currentState.dynamicVideos || {},
      selectedVoice: currentState.selectedVoice?.selectedVoice || null,
      familyVoice: currentState.familyVoice?.familyVoice || null,
      stripe: currentState.stripe?.stripe || {},
    };
    logger.debug(`Selected state before saving:, ${selectedState}`);
    try {
      const { csrfToken } = currentState.auth;
      logger.debug(`CSRF Token before making request:, ${csrfToken}`);
      const response = await axiosInstance.post(
        apiUrl,
        { state: selectedState },
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        // Handle success response as needed
        logger.info("Entire state saved successfully");
      } else {
        throw new Error("Failed to save entire state");
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.error || error.message || "Unknown error";
      logger.error("Error saving entire state:", errorMessage);
    }
  }

  return result;
};

export default saveFormDataMiddleware;
