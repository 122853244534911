import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Typography,
  Container,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useSelector } from "react-redux";
import axiosInstance from "../axiosConfig";
import ShowPhotoModal from "../ShowPhotoModal";
import logger from "../../utils/logger";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";

const DynamicPhotosPage = ({ pageTitle, storyLabel, storageKey }) => {
  const [photos, setPhotos] = useState([]);
  const error = useSelector((state) => state.dynamicPhotos?.error);
  const photoDescriptions = useSelector(
    (state) => state.dynamicPhotos?.[storageKey]?.dynamicPhotos || []
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [descriptions, setDescriptions] = useState(photoDescriptions);
  // eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState("");
  const [refresh, setRefresh] = useState(true);
  const [fetching, setFetching] = useState(true);
  const { csrfToken } = useSelector((state) => state.auth);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const handleOpenPhotoModal = (photo) => setSelectedPhoto(photo);
  const handleClosePhotoModal = () => setSelectedPhoto(null);
  const [isPlaying, setIsPlaying] = useState(null); // Track which story is playing
  const [speechSynthesisInstance, setSpeechSynthesisInstance] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const familyId = user?.familyName?.familyId;
  const selectedVoiceObject = useSelector(
    (state) =>
      state.familyVoice?.familyVoice.find(
        (voice) => voice.familyId === familyId
      ) || null
  );
  const [fallbackVoice, setFallbackVoice] = useState(null);
  const [localSelectedVoice, setLocalSelectedVoice] = useState(fallbackVoice);
  // eslint-disable-next-line
  const [voices, setVoices] = useState([]);

  useEffect(() => {
    const loadVoices = () => {
      const availableVoices = speechSynthesis.getVoices();
      if (availableVoices.length > 0) {
        const defaultVoice = availableVoices.find(
          (voice) => voice.lang === "en-US"
        );
        setVoices(availableVoices);
        setFallbackVoice(defaultVoice || availableVoices[0]); // Use the first available voice as a fallback
      }
    };

    loadVoices();

    if (speechSynthesis.onvoiceschanged !== undefined) {
      speechSynthesis.onvoiceschanged = loadVoices;
    }
  }, []);

  useEffect(() => {
    if (selectedVoiceObject) {
      setLocalSelectedVoice(selectedVoiceObject);
    } else {
      setLocalSelectedVoice(fallbackVoice);
    }
  }, [selectedVoiceObject, fallbackVoice]);

  useEffect(() => {
    if (
      Array.isArray(photos) &&
      photos.length > 0 &&
      Array.isArray(photoDescriptions) &&
      photoDescriptions.length > 0
    ) {
      const updatedDescriptions = photos.map((photo) => {
        const matchingDescription = photoDescriptions.find(
          (desc) => String(desc.Key) === String(photo.Key)
        );
        return matchingDescription ? matchingDescription.text : "";
      });
      setDescriptions(updatedDescriptions);
    }
  }, [photos, photoDescriptions]);

  useEffect(() => {}, [selectedFile]);

  useEffect(() => {
    setPhotos([]);
  }, [storageKey]);

  useEffect(() => {
    fetchPhotos();
  }, [storageKey]);

  useEffect(() => {
    if (!refresh) return;

    fetchPhotos();
    setRefresh(false);
  }, [refresh, csrfToken]);

  const fetchPhotos = async () => {
    setFetching(true);
    try {
      const response = await axiosInstance.get(
        "https://memorydiaries.com/lifeData/photos/download",
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      logger.debug("Response data:", response.data);
      logger.info("Photos fetched successfully");
      if (Array.isArray(response.data)) {
        const filteredData = response.data.filter(
          (item) => item.Key && item.Key.startsWith(`${storageKey}_`)
        );

        const updatedPhotos = filteredData.map((photo) => {
          logger.info(`photo name: ${photo.Key}`);
          const modifiedStorageKey = photo.Key
            ? photo.Key.replace(new RegExp(`^${storageKey}_`), "") // Remove everything before and including the first underscore
            : ""; // Default to an empty string or handle differently if filename is undefined

          return { ...photo, Key: modifiedStorageKey }; // Replace the fileKey with the modified one
        });

        setPhotos(updatedPhotos);
        const initialDescriptions = updatedPhotos.map(() => "");
        setDescriptions(initialDescriptions);
        setSelectedFile(null);
      } else {
        logger.error("Unexpected response format:", response.data);
      }
    } catch (error) {
      logger.error("Error fetching photos:", error);
      setErrorMessage("Error fetching photos. Please try again later.");
    } finally {
      setFetching(false);
    }
  };

  const handleDownload = (photoName) => {
    const selectedPhoto = photos.find((pho) => pho.Key === photoName);

    if (selectedPhoto && selectedPhoto.base64) {
      // Decode base64 if `selectedPhoto.file` contains base64 data
      const binaryString = atob(selectedPhoto.base64);
      const byteArray = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([byteArray], { type: selectedPhoto.contentType });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = photoName; // Use photoName as the file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);
    } else {
      setErrorMessage("Photo not available for download");
    }
  };

  const handlePlayPauseTTS = (text, index) => {
    if (isPlaying === index) {
      speechSynthesis.cancel();
      setIsPlaying(null);
    } else {
      if (speechSynthesisInstance) speechSynthesis.cancel(); // Stop any existing instance
      const utterance = new SpeechSynthesisUtterance(text);

      const availableVoices = speechSynthesis.getVoices();
      const fallbackVoice =
        availableVoices.find((voice) => voice.lang === "en-US") ||
        availableVoices[0]; // Default to first available voice

      utterance.voice =
        availableVoices.find(
          (voice) => voice.name === localSelectedVoice.familyVoice
        ) || fallbackVoice;

      speechSynthesis.speak(utterance);
      setIsPlaying(index);

      utterance.onend = () => setIsPlaying(null);
      setSpeechSynthesisInstance(utterance);
    }
  };

  return (
    <Container style={{ marginTop: 20 }}>
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        style={{
          fontFamily: "'Days One', sans-serif",
          fontStyle: "italic",
          marginBottom: 20,
        }}
      >
        {user.name ? `${user.name}'s ${pageTitle}:` : `${pageTitle}:`}
      </Typography>

      <Box sx={{ mt: 4 }}>
        <Typography
          variant="h6"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          {storyLabel}
        </Typography>
        {fetching ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <CircularProgress color="success" />
            <Typography
              variant="body1"
              style={{ fontFamily: "'Days One', sans-serif" }}
            >
              Loading photos...
            </Typography>
          </Box>
        ) : photos.length > 0 ? (
          <List>
            {photos.map((photo, index) => (
              <ListItem
                key={index}
                sx={{
                  display: "grid",
                  alignItems: "center",
                  gridTemplateColumns: "30fr 6fr 25fr 6fr",
                  columnGap: "20px",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  padding: "20px",
                  marginBottom: "10px",
                  boxShadow: 3,
                }}
              >
                <Box sx={{ gridColumn: "1 / span 1" }}>
                  <ListItemAvatar style={{ marginRight: "10px" }}>
                    <Avatar
                      src={`data:${photo.contentType};base64,${photo.base64}`}
                      alt={`Photo ${index + 1}`}
                      variant="square"
                      sx={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenPhotoModal(photo)}
                    />
                  </ListItemAvatar>
                </Box>

                {descriptions[index] && (
                  <Box
                    sx={{
                      gridColumn: "2 / span 1",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "20px",
                      marginRight: "50px",
                    }}
                  >
                    <IconButton
                      aria-label="play-pause"
                      onClick={() =>
                        handlePlayPauseTTS(descriptions[index], index)
                      }
                      sx={{
                        display: "flex",
                        alignItems: "center", // Centers the item vertically
                        justifyContent: "center", // Centers the item horizontally (optional)
                        width: 100,
                        height: 100,
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.1)",
                        },
                        "& svg": {
                          fontSize: 48,
                        },
                      }}
                    >
                      {isPlaying === index ? (
                        <PauseCircleIcon />
                      ) : (
                        <PlayCircleIcon />
                      )}
                    </IconButton>
                  </Box>
                )}

                <Box
                  sx={{
                    gridColumn: "3 / span 1",
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "20px",
                    width: "100%",
                    marginRight: "50px",
                  }}
                >
                  <ListItemText
                    primary={photo.Key || `Photo ${index + 1}`}
                    sx={{
                      marginBottom: "20px",
                      "& .MuiTypography-root": {
                        fontFamily: "'Days One', sans-serif",
                      },
                    }}
                  />
                  <>
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "'Verdana', sans-serif",
                        marginBottom: "20px",
                        width: "100%",
                      }}
                    >
                      {descriptions[index]}
                    </Typography>
                  </>
                </Box>

                <Box
                  sx={{
                    gridColumn: "4 / span 1",
                    display: "flex",
                    marginLeft: "50px",
                    gap: 1,
                  }}
                >
                  <IconButton
                    edge="end"
                    aria-label="download"
                    onClick={() => handleDownload(photo.Key)}
                    sx={{
                      width: 100,
                      height: 100,
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                      },
                      "& svg": {
                        fontSize: 48,
                      },
                    }}
                  >
                    <DownloadIcon />
                  </IconButton>
                </Box>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography
            variant="body1"
            style={{ fontFamily: "'Days One', sans-serif" }}
          >
            No photos uploaded yet.
          </Typography>
        )}
      </Box>

      <ShowPhotoModal
        selectedPhoto={selectedPhoto}
        handleClosePhotoModal={handleClosePhotoModal}
      />
      {error && (
        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </Container>
  );
};

export default DynamicPhotosPage;
