import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Placeholder from "../Img/ProfilePlaceholder.jpg";
import {
  deleteUser,
  profilePhotoDeleted,
  updateCode,
  nodeMail,
} from "../store/authSlice";
import axiosInstance from "./axiosConfig";
import { deleteBackendData } from "../store/fetchBackendData";
import { deleteDocumentsForUser } from "../store/MyDocumentSlice";
import { deleteAllPhotos } from "../store/DynamicPhotoSlice";
import { deleteAllVideos } from "../store/DynamicVideoSlice";
import { deleteCustomerAndSubscription } from "../store/stripeSlice";
import { Button, Grid } from "@mui/material";
import { fetchFamilyMembers } from "../store/FamilySlice";
import { format, isValid } from "date-fns";
import logger from "../utils/logger";
import { saveSelectedVoice } from "../store/voiceSlice";
import Typography from "@mui/material/Typography";

function Profile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const state = useSelector((state) => state);
  const [profileImage, setProfileImage] = useState(user?.profileImage || "");
  const { csrfToken } = useSelector((state) => state.auth);
  const paymentPlan = useSelector((state) => state.stripe.planId);
  const [code, setCode] = useState(state.auth.user.code);
  const [voices, setVoices] = useState([]);
  const selectedVoice = useSelector(
    (state) => state.selectedVoice?.selectedVoice || null
  );
  const [fallbackVoice, setFallbackVoice] = useState(null);
  const [localSelectedVoice, setLocalSelectedVoice] = useState(fallbackVoice);

  useEffect(() => {
    const loadVoices = () => {
      const availableVoices = speechSynthesis.getVoices();
      if (availableVoices.length > 0) {
        const defaultVoice = availableVoices.find(
          (voice) => voice.lang === "en-US"
        );
        setVoices(availableVoices);
        setFallbackVoice(defaultVoice || availableVoices[0]); // Use the first available voice as a fallback
      }
    };

    loadVoices();

    if (speechSynthesis.onvoiceschanged !== undefined) {
      speechSynthesis.onvoiceschanged = loadVoices;
    }
  }, []);

  useEffect(() => {
    if (selectedVoice) {
      const voice = voices.find((v) => v.name === selectedVoice);
      if (voice) {
        setLocalSelectedVoice(voice);
      } else {
        setLocalSelectedVoice(fallbackVoice);
      }
    } else {
      setLocalSelectedVoice(fallbackVoice);
    }
  }, [selectedVoice, fallbackVoice]);

  const handleVoiceChange = (event) => {
    const selectedVoiceName = event.target.value;
    logger.info(
      `Selected voice name in handleVoiceChange: ${JSON.stringify(
        selectedVoiceName
      )}`
    );
    const voice = voices.find((v) => v.name === selectedVoiceName);
    if (voice) {
      setLocalSelectedVoice(voice); // Set localSelectedVoice to the full voice object
      logger.info(
        `Updated local selected voice in handleVoiceChange: ${JSON.stringify(
          voice
        )}`
      );
    } else {
      logger.error("unable to find matching voice in handleVoiceChange");
    }

    playTestAudio(voice);
  };

  const handleSaveVoice = () => {
    logger.debug(
      `inside handleSaveVoice: ${JSON.stringify(localSelectedVoice)}`
    );
    dispatch(saveSelectedVoice(localSelectedVoice.name));
    alert("Voice selection saved successfully");
  };

  const playTestAudio = (voice) => {
    const utterance = new SpeechSynthesisUtterance(`${voice.name}`);
    utterance.voice = voice;
    utterance.rate = 1; // Adjust rate as needed
    utterance.pitch = 1; // Adjust pitch as needed

    window.speechSynthesis.cancel();
    window.speechSynthesis.speak(utterance);
  };

  const generateCode = () => {
    let genCode = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 6; i++) {
      genCode += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return genCode;
  };

  const isUniqueCode = async (code) => {
    try {
      const response = await axiosInstance.post(
        "https://memorydiaries.com/lifeauth/check-code-unique",
        { code },
        { withCredentials: true }
      );
      logger.debug("Checking code uniqueness:", response.data.isUnique);
      return response.data.isUnique;
    } catch (error) {
      logger.error("Error checking code uniqueness:", error);
      return false;
    }
  };

  const generateUniquePassword = async () => {
    let unique = false;
    let newPassword;

    while (!unique) {
      newPassword = generateCode();
      unique = await isUniqueCode(newPassword);
    }

    setCode(newPassword);
    dispatch(updateCode({ code: newPassword }));
  };

  const handleEdit = () => {
    navigate("/edit-profile");
  };

  useEffect(() => {
    logger.debug("Payment Plan:", paymentPlan);
    logger.debug("Code:", code);
  }, [paymentPlan, code]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        logger.info("Fetching photo in profile");
        await getProfilePhoto();
      } catch (error) {
        logger.error("Error fetching profile photo:", error);
      }

      try {
        logger.info("Fetching family members in profile");
        await fetchFamilyMembers();
      } catch (error) {
        logger.error("Error fetching family members:", error);
      }
    };

    fetchData();
  }, []);

  const getProfilePhoto = async () => {
    try {
      const response = await axiosInstance.get(
        "https://memorydiaries.com/lifeauth/profile-get",
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true, // Corrected typo here
        }
      );

      if (response.status === 200) {
        const result = response.data[0];
        logger.debug("Profile image result:", result);
        const base64ImageUrl = `data:${result.contentType};base64,${result.base64}`;
        setProfileImage(base64ImageUrl);
        logger.info("Profile image fetched successfully");
      } else {
        logger.error("Failed to get image", response.data);
      }
    } catch (error) {
      logger.error("Error fetching profile photo:", error);
    }
  };

  const handleDelete = () => {
    if (
      window.confirm(
        "Are you sure you want to delete your account? This action cannot be undone."
      )
    ) {
      dispatch(deleteUser());
      dispatch(profilePhotoDeleted());
      dispatch(deleteBackendData());
      dispatch(deleteDocumentsForUser());
      dispatch(deleteAllPhotos());
      dispatch(deleteAllVideos());
      dispatch(deleteCustomerAndSubscription())
        .then((result) => {
          logger.info("Delete user result:", result);
          if (result.meta.requestStatus === "fulfilled") {
            alert("Account deletion succeeded, navigating to home");
            navigate("/");
          } else {
            alert("Failed to delete account");
          }
        })
        .catch((error) => {
          logger.error("Delete user error:", error);
          alert("An error occurred while trying to delete your account");
        });
    }
  };

  const paymentType = (paymentPlan) => {
    console.log("Payment Plan in Profile", paymentPlan);
    switch (paymentPlan) {
      case "P-27A05978RC4239114M4VOXMY":
        return "Montly Plan";
      case "P-91X40889A7040131WM4VUNEY":
        return "Six Monthly Plan";
      case "P-3MN67890N6614404PM4VPUOI":
        return "Yearly Plan";
      case "P-Free-Plan":
        return "Free Plan";
      default:
        return "Free Plan";
    }
  };

  const handleEditFamily = () => {
    navigate("/edit-family");
  };

  const handleAddFamily = () => {
    navigate("/add-family");
  };

  const handleUpdatePayment = () => {
    navigate("/update-payment");
  };

  const handleNewCode = () => {
    generateUniquePassword();
  };

  const handleEmailCode = async () => {
    for (const member of state.family.familyMembers) {
      try {
        logger.info(`Sending email to family member: ${member.email}`);
        await dispatch(
          nodeMail({
            subject: "UpdateCode",
            to_email: member.email,
            name: user.name,
            password: "", // Optional if not needed
            code: code,
          })
        ).unwrap(); // Unwrap to catch errors in try-catch
      } catch (error) {
        logger.error("Failed to send email:", error);
      }
    }
  };

  const buttonStyle = {
    fontFamily: "'Days One', sans-serif",
    backgroundColor: "#E29578",
    color: "white",
    borderRadius: 10,
    width: "100%",
  };

  const deleteButtonStyle = {
    fontFamily: "'Days One', sans-serif",
    backgroundColor: "red",
    color: "white",
    borderRadius: 10,
    width: "100%",
  };

  const buttonData = [
    { label: "Edit", handler: handleEdit },
    { label: "Add Family Member", handler: handleAddFamily },
    { label: "Edit Family Members", handler: handleEditFamily },
    { label: "Update Payment Details", handler: handleUpdatePayment },
    { label: "New Family Code", handler: handleNewCode },
    { label: "Email Family Code", handler: handleEmailCode },
  ];

  return (
    <div
      style={{
        padding: "20px",
        maxWidth: "100%",
        margin: "auto",
        fontSize: "18px",
      }}
    >
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        style={{
          fontFamily: "'Days One', sans-serif",
          fontStyle: "italic",
          marginBottom: 20,
        }}
      >
        Profile
      </Typography>
      {user && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              width: "45%",
            }}
          >
            {[
              { label: "User Name", value: user.name },
              { label: "Email Address", value: user.email },
              {
                label: "Date of Birth",
                value: format(new Date(user.dob), "do 'of' MMMM yyyy"),
              },
              { label: "Payment Plan", value: paymentType(paymentPlan) },
              { label: "Phone Number", value: user.phoneNumber },
              { label: "Postal Address", value: user.address },
              { label: "Code for Family Member Login", value: user.code },
              {
                label: "Selected Voice",
                value: (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "colummn",
                      gap: "10px",
                    }}
                  >
                    <select
                      onChange={handleVoiceChange}
                      value={localSelectedVoice ? localSelectedVoice.name : ""} // Use localSelectedVoice.name for the display
                      style={{
                        fontFamily: "Verdana, sans-serif",
                        maxWidth: "150px",
                      }}
                    >
                      {voices.length > 0 ? (
                        voices.map((voice) => (
                          <option key={voice.name} value={voice.name}>
                            {voice.name} ({voice.lang})
                          </option>
                        ))
                      ) : (
                        <option>Loading available voices...</option>
                      )}
                    </select>
                    <Button onClick={handleSaveVoice} style={buttonStyle}>
                      Save Voice Choice
                    </Button>
                  </div>
                ),
              },
            ].map((item, index) => (
              <div
                key={item.label}
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1.5fr",
                  justifyContent: "space-between",
                  gap: "10px",
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                }}
              >
                <label
                  style={{
                    fontWeight: "bold",
                    fontFamily: "'Days One', sans-serif",
                  }}
                >
                  {item.label}:
                </label>
                <div style={{ fontFamily: "Verdana, sans-serif" }}>
                  {item.label === "Selected Voice" ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <select
                        onChange={handleVoiceChange}
                        value={
                          localSelectedVoice ? localSelectedVoice.name : ""
                        } // Use localSelectedVoice.name for the display
                        style={{
                          fontFamily: "Verdana, sans-serif",
                          width: "100%", // Ensures the select box takes the full width
                          padding: "10px", // Sets padding for the select box
                          borderRadius: "10px", // Sets the same border radius
                        }}
                      >
                        {voices.length > 0 ? (
                          voices.map((voice) => (
                            <option key={voice.name} value={voice.name}>
                              {voice.name} ({voice.lang})
                            </option>
                          ))
                        ) : (
                          <option>Loading available voices...</option>
                        )}
                      </select>
                      <Button
                        onClick={handleSaveVoice}
                        style={{
                          ...buttonStyle,
                          width: "100%",
                          padding: "10px",
                        }}
                      >
                        Save Voice Choice
                      </Button>
                    </div>
                  ) : (
                    item.value
                  )}
                </div>
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              marginLeft: "10%",
              width: "40%",
            }}
          >
            <img
              src={profileImage || Placeholder}
              alt="Profile"
              style={{
                marginTop: "10px",
                width: "100%",
                height: "100%",
                aspectRatio: "1 / 1",
                objectFit: "cover",
                borderRadius: "10px",
              }}
            />
          </div>
        </div>
      )}
      <div
        style={{
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "8px",
          width: "100%",
          marginTop: "20px",
        }}
      >
        <label
          style={{
            fontWeight: "bold",
            fontFamily: "'Days One', sans-serif",
          }}
        >
          Authorized Family Members:
        </label>
        <div>
          {state.family?.familyMembers?.length > 0 ? (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr 2fr 3fr 3fr",
                fontFamily: "Verdana, sans-serif",
              }}
            >
              {state.family.familyMembers.map((member) => (
                <>
                  <div key={member.id} style={{ padding: "4px 0" }}>
                    {member.name}
                  </div>
                  <div key={member.id} style={{ padding: "4px 0" }}>
                    <b>Relation:</b> {member.relation}
                  </div>
                  <div key={member.id} style={{ padding: "4px 0" }}>
                    <b>Ph:</b> {member.phoneNumber}
                  </div>
                  <div key={member.id} style={{ padding: "4px 0" }}>
                    <b>Email:</b> {member.email}
                  </div>
                  <div key={member.id} style={{ padding: "4px 0" }}>
                    <b>DOB:</b>{" "}
                    {member.dob && isValid(new Date(member.dob))
                      ? format(new Date(member.dob), "do 'of' MMMM yyyy")
                      : ""}
                  </div>
                </>
              ))}
            </div>
          ) : (
            <div>No authorized family members found</div>
          )}
        </div>
      </div>

      <Grid container spacing={2} sx={{ mt: 4 }}>
        {buttonData.map((button) => (
          <Grid item xs={12} sm={6} key={button.label}>
            <Button
              fullWidth
              variant="contained"
              onClick={button.handler}
              style={buttonStyle}
            >
              {button.label}
            </Button>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} sx={{ mt: 4 }}>
        <Button
          variant="contained"
          onClick={handleDelete}
          style={deleteButtonStyle}
          fullWidth // Ensure full width
        >
          Delete Account
        </Button>
      </Grid>
    </div>
  );
}

export default Profile;
