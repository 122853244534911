// src/components/DynamicStoriesPage.js
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Typography, Container, Box, IconButton } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";

// eslint-disable-next-line
const descriptionRegex = /^[a-zA-Z0-9\s.,!?'"(){}\[\]\/-]*$/;

const DynamicStoriesPage = ({ pageTitle, storyLabel, storageKey }) => {
  const [isPlaying, setIsPlaying] = useState(null); // Track which story is playing
  const [speechSynthesisInstance, setSpeechSynthesisInstance] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const familyId = user?.familyName?.familyId;
  const selectedVoiceObject = useSelector(
    (state) =>
      state.familyVoice?.familyVoice?.find(
        (voice) => voice.familyId === familyId
      ) || null
  );
  const [fallbackVoice, setFallbackVoice] = useState(null);
  const [localSelectedVoice, setLocalSelectedVoice] = useState(fallbackVoice);
  // eslint-disable-next-line
  const [voices, setVoices] = useState([]);

  const stories = useSelector(
    (state) => state.dynamicStories?.[storageKey].stories || []
  );

  useEffect(() => {
    const loadVoices = () => {
      const availableVoices = speechSynthesis.getVoices();
      if (availableVoices.length > 0) {
        const defaultVoice = availableVoices.find(
          (voice) => voice.lang === "en-US"
        );
        setVoices(availableVoices);
        setFallbackVoice(defaultVoice || availableVoices[0]); // Use the first available voice as a fallback
      }
    };

    loadVoices();

    if (speechSynthesis.onvoiceschanged !== undefined) {
      speechSynthesis.onvoiceschanged = loadVoices;
    }
  }, []);

  useEffect(() => {
    if (selectedVoiceObject) {
      setLocalSelectedVoice(selectedVoiceObject);
    } else {
      setLocalSelectedVoice(fallbackVoice);
    }
  }, [selectedVoiceObject, fallbackVoice]);

  const handlePlayPauseTTS = (text, index) => {
    if (isPlaying === index) {
      speechSynthesis.cancel();
      setIsPlaying(null);
    } else {
      if (speechSynthesisInstance) speechSynthesis.cancel(); // Stop any existing instance
      const utterance = new SpeechSynthesisUtterance(text);

      const availableVoices = speechSynthesis.getVoices();
      const fallbackVoice =
        availableVoices.find((voice) => voice.lang === "en-US") ||
        availableVoices[0]; // Default to first available voice

      utterance.voice =
        availableVoices.find(
          (voice) => voice.name === localSelectedVoice.familyVoice
        ) || fallbackVoice;

      speechSynthesis.speak(utterance);
      setIsPlaying(index);

      utterance.onend = () => setIsPlaying(null);
      setSpeechSynthesisInstance(utterance);
    }
  };

  return (
    <Container style={{ marginTop: 20 }}>
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        style={{
          fontFamily: "'Days One', sans-serif",
          fontStyle: "italic",
          marginBottom: 20,
        }}
      >
        {user.name ? `${user.name}'s ${pageTitle}` : `${pageTitle}`}
      </Typography>
      <Box sx={{ mt: 4 }}>
        <Typography
          variant="h6"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          {storyLabel}
        </Typography>

        {stories.length > 0 ? (
          stories.map((s, index) => (
            <Box
              key={index}
              sx={{
                mb: 2,
                p: 2,
                border: "1px solid #ccc",
                borderRadius: "10px",
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                aria-label="play-pause"
                onClick={() => handlePlayPauseTTS(s.text, index)}
                sx={{
                  marginRight: 1,
                  width: 100,
                  height: 100,
                  color: isPlaying === index ? "red" : "inherit",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                  },
                  "& svg": {
                    fontSize: 48,
                  },
                }}
              >
                {isPlaying === index ? <PauseCircleIcon /> : <PlayCircleIcon />}
              </IconButton>

              <Typography
                variant="body1"
                sx={{ flexGrow: 1, fontFamily: "Verdana, sans-serif" }}
              >
                {s.text}
              </Typography>
            </Box>
          ))
        ) : (
          <Typography
            variant="body1"
            style={{ fontFamily: "'Days One', sans-serif" }}
          >
            No stories recorded yet.
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default DynamicStoriesPage;
