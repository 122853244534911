import React from "react";
import { Button, Container, Typography, Box } from "@mui/material";

const PrivacyPolicy = ({ onClose }) => {
  const handleBack = () => {
    onClose();
  };

  const buttonStyle = {
    fontSize: "18px",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#E29578",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    borderRadius: 10,
  };

  const buttonHoverStyle = {
    backgroundColor: "#FFDDD2",
  };

  return (
    <Container maxWidth="md" style={{ padding: "20px", lineHeight: "1.6" }}>
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        style={{
          fontFamily: "'Days One', sans-serif",
          fontWeight: "bold",
          marginBottom: "20px",
        }}
      >
        Privacy Policy
      </Typography>

      <Box mb={4}>
        <Typography variant="body1" paragraph style={{ fontFamily: "Verdana" }}>
          This Privacy Policy describes how your personal information is
          collected, used, and shared when you visit or make a submission to
          Memory Diaries (the “Site”).
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Personal Information We Collect
        </Typography>
        <Typography variant="body1" paragraph style={{ fontFamily: "Verdana" }}>
          When you visit the Site, we automatically collect certain information
          about your device, including your web browser, user ID, and IP
          address. Additionally, as you browse the Site, we may collect
          information about the individual pages or content you view, the
          websites or search terms that referred you to the Site, and how you
          interact with the Site. We refer to this automatically-collected
          information as “Device Information”.
        </Typography>
        <Typography
          variant="body1"
          paragraph
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          We collect Device Information using the following technologies:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1" style={{ fontFamily: "Verdana" }}>
              <strong>Log files:</strong> Track actions occurring on the Site
              and collect data including your IP address, browser type, Internet
              service provider, referring/exit pages, and date/time stamps.
            </Typography>
          </li>
        </ul>
        <Typography variant="body1" paragraph style={{ fontFamily: "Verdana" }}>
          Additionally, when you create an account, record a story, or share
          your life event on the Site, we collect certain information, including
          your name, email address, phone number, and any other personal details
          you provide through your submissions. We refer to this information as
          “User Information”.
        </Typography>
        <Typography variant="body1" paragraph style={{ fontFamily: "Verdana" }}>
          When we refer to “Personal Information” in this Privacy Policy, we are
          referring both to Device Information and User Information.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          How We Use Your Personal Information
        </Typography>
        <Typography variant="body1" paragraph style={{ fontFamily: "Verdana" }}>
          We use the User Information we collect to:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1" style={{ fontFamily: "Verdana" }}>
              Enable you to create and manage your Memory Diaries profile on the
              Site.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" style={{ fontFamily: "Verdana" }}>
              Allow you to record and share stories, events, and memories with
              family and friends.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" style={{ fontFamily: "Verdana" }}>
              Communicate with you regarding updates, changes, and new features
              of the Site.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" style={{ fontFamily: "Verdana" }}>
              Provide customer support, respond to inquiries, and troubleshoot
              issues.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" style={{ fontFamily: "Verdana" }}>
              Enhance and optimize your experience on the Site, including
              analyzing how users interact with the Site to improve
              functionality.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" style={{ fontFamily: "Verdana" }}>
              Send you promotional emails or notifications based on your
              preferences, with an option to unsubscribe at any time.
            </Typography>
          </li>
        </ul>
        <Typography variant="body1" paragraph style={{ fontFamily: "Verdana" }}>
          We use Device Information to help us screen for potential risk or
          fraud (particularly your IP address) and to improve and optimize the
          Site (for example, by generating analytics about how users browse and
          interact with the Site, and to assess the success of our marketing
          campaigns).
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Sharing Your Personal Information
        </Typography>
        <Typography variant="body1" paragraph style={{ fontFamily: "Verdana" }}>
          We share your Personal Information with trusted third-party service
          providers who help us operate the Site and provide services to you.
          For example, we use cloud storage services to store your stories
          securely and payment processors to handle transactions. These service
          providers are required to protect your information in accordance with
          this Privacy Policy.
        </Typography>
        <Typography
          variant="body1"
          paragraph
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          We also share your information with the following third parties:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1" style={{ fontFamily: "Verdana" }}>
              <strong>Cloud Storage Providers:</strong> For securely storing
              your encrypted content (stories, photos, videos) and any other
              information you upload to the Site.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" style={{ fontFamily: "Verdana" }}>
              <strong>PayPal Payment Processors:</strong> For processing
              transactions securely if you make a purchase or donate on the
              Site.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" style={{ fontFamily: "Verdana" }}>
              <strong>Legal Requirements:</strong> We may also share your
              information to comply with applicable laws, regulations, or legal
              processes (such as subpoenas) and to protect our legal rights.
            </Typography>
          </li>
        </ul>
      </Box>

      <Box mb={4}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Changes to this Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this Privacy Policy from time to time to reflect changes
          to our practices or for other operational, legal, or regulatory
          reasons. We will post the updated Privacy Policy on the Site with the
          revised date at the top.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions or concerns about this Privacy Policy or our
          privacy practices, please contact us at:{" "}
          <a href="mailto:dr.dbrown@icloud.com">
            <strong>dr.dbrown@icloud.com</strong>
          </a>
        </Typography>
      </Box>

      <Button
        onClick={handleBack}
        variant="contained"
        style={{
          ...buttonStyle,
          width: "48%",
          fontFamily: "'Days One', sans-serif",
        }}
        onMouseOver={(e) =>
          (e.currentTarget.style.backgroundColor =
            buttonHoverStyle.backgroundColor)
        }
        onMouseOut={(e) =>
          (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor)
        }
        onFocus={(e) =>
          (e.currentTarget.style.backgroundColor =
            buttonHoverStyle.backgroundColor)
        }
        onBlur={(e) =>
          (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor)
        }
        aria-label="Go back"
      >
        Back
      </Button>
    </Container>
  );
};

export default PrivacyPolicy;
