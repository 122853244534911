import React from "react";
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ShowModal = ({ showVideo, selectedVideo, handleCloseVideoModal }) => {
  return (
    <Dialog
      open={Boolean(showVideo)}
      onClose={handleCloseVideoModal}
      maxWidth="md"
    >
      <DialogTitle style={{ fontFamily: "'Days One', sans-serif" }}>
        Video
        <IconButton
          aria-label="close"
          onClick={handleCloseVideoModal}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ overflow: "visible" }}>
        {selectedVideo ? (
          <video controls style={{ width: "100%", height: "auto" }}>
            <source src={selectedVideo} type="video/mp4" />
          </video>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <CircularProgress color="success" />
            <Typography
              variant="body1"
              style={{ fontFamily: "Verdana, sans-serif" }}
            >
              Loading videos...
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseVideoModal}
          style={{
            margin: "16px 0",
            borderColor: "#E29578",
            backgroundColor: "#E29578",
            "&:hover": {
              backgroundColor: "#FFDDD2",
            },
            fontFamily: "'Days One', sans-serif",
            color: "white",
            padding: "5px 10px",
            borderRadius: 10,
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShowModal;
