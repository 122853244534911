import React, { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { nodeMail } from "../store/authSlice";
import DOMPurify from "dompurify";
import axiosInstance from "./axiosConfig";
import logger from "../utils/logger";

function ResetPass({ open, onClose }) {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const { csrfToken } = useSelector((state) => state.auth);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const generateRandomPassword = () => {
    const chars =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";
    for (let i = 0; i < 8; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return password;
  };

  const handleResetPassword = async () => {
    const sanitizedEmail = DOMPurify.sanitize(email);
    if (!emailRegex.test(sanitizedEmail)) {
      alert("Invalid email format");
      return;
    }
    const newPassword = generateRandomPassword();

    try {
      const response = await axiosInstance.post(
        `https://memorydiaries.com/lifeauth/reset-password`,
        {
          email: sanitizedEmail,
          password: newPassword,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );

      if (response.status !== 200) {
        logger.error("Failed to update password in the auth API");
        throw new Error("Failed to update password in the auth API");
      }
      logger.info("Password rests request returned 200");

      try {
        logger.info(`Sending welcome email to: ${sanitizedEmail}`);
        await dispatch(
          nodeMail({
            subject: "Welcome",
            to_email: sanitizedEmail,
            name: "",
            password: newPassword, // Optional if not needed
            code: "",
          })
        ).unwrap(); // Unwrap to catch errors in try-catch
      } catch (error) {
        logger.error("Failed to send email:", error);
      }

      alert(`Password reset link sent to ${sanitizedEmail}`);
      onClose();
    } catch (error) {
      logger.error("Failed to reset password:", error);
      alert("Failed to reset password. Please try again.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        style={{
          fontFamily: "'Days One', sans-serif",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Reset Password
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter your email to receive a password reset link.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Email"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ fontFamily: "Verdana, sans-serif" }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleResetPassword}
          color="primary"
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Send Reset Link
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ResetPass;
