import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Modal,
  Box,
  ListItemIcon,
  TextField,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountBoxIcon from "@mui/icons-material/Face";
import PhotoLibraryIcon from "@mui/icons-material/PhotoAlbum";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import EmailIcon from "@mui/icons-material/Email";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CottageIcon from "@mui/icons-material/Cottage";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate, Link } from "react-router-dom";
import NewPageForm from "./components/NewPageForm";
import { editPageConfig, deletePageConfig } from "./store/pageConfigSlice";
import { deleteStoriesByStorageKey } from "./store/DynamicStorySlice";
import {
  deletePhotoDescriptionsByStorageKey,
  deletePhotosByStorageKey,
} from "./store/DynamicPhotoSlice";
import {
  deleteVideoDescriptionsByStorageKey,
  deleteVideosByStorageKey,
} from "./store/DynamicVideoSlice";

const NavigationDrawer = () => {
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false); // New state for edit mode
  const [editingTitle, setEditingTitle] = useState(null); // New state for title being edited
  const pageConfigs = useSelector((state) => state.pageConfig.pageConfig || []);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Edit navbar toggled");
  }, [editMode]);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const toggleDrawer = (drawerOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(drawerOpen);
  };

  const toggleModal = () => setModalOpen(!modalOpen);
  const toggleEditMode = () => setEditMode((prev) => !prev);

  const getIcon = (text) => {
    switch (text) {
      case "Home":
        return <CottageIcon sx={{ color: "white" }} />;
      case "Profile":
        return <AccountBoxIcon sx={{ color: "white" }} />;
      case "My Documents":
        return <FileCopyIcon sx={{ color: "white" }} />;
      case "My Photos":
        return <PhotoLibraryIcon sx={{ color: "white" }} />;
      case "My Videos":
        return <VideoLibraryIcon sx={{ color: "white" }} />;
      case "Contact Us":
        return <EmailIcon sx={{ color: "white" }} />;
      default:
        return <MenuBookIcon sx={{ color: "white" }} />;
    }
  };

  const handleEditTitleChange = (event, storageKey) => {
    setEditingTitle({ storageKey, title: event.target.value });
  };

  const handleSaveEditTitle = (
    storageKey,
    editingTitle,
    pagetype,
    storyLabel
  ) => {
    dispatch(
      editPageConfig({
        storageKey,
        newConfig: {
          storageKey: storageKey,
          pageTitle: editingTitle.title,
          pageType: pagetype,
          storyLabel: storyLabel,
        },
      })
    );
    setEditingTitle(null);
  };

  const handleDeletePage = (storageKey, pageType) => {
    switch (pageType) {
      case "story":
        if (
          window.confirm(
            "Are you sure you want to delete this page and all stories linked to the page?"
          )
        ) {
          dispatch(deleteStoriesByStorageKey({ storageKey }));
          dispatch(deletePageConfig({ storageKey }));
        }
        break;

      case "photo":
        if (
          window.confirm(
            "Are you sure you want to delete this page and all photos and description linked to the page?"
          )
        ) {
          dispatch(deletePhotosByStorageKey({ storageKey }));
          dispatch(deletePhotoDescriptionsByStorageKey({ storageKey }));
          dispatch(deletePageConfig({ storageKey }));
        }
        break;

      case "video":
        if (
          window.confirm(
            "Are you sure you want to delete this page and all videos and descriptions linked to the page?"
          )
        ) {
          dispatch(deleteVideosByStorageKey({ storageKey }));
          dispatch(deleteVideoDescriptionsByStorageKey({ storageKey }));
          dispatch(deletePageConfig({ storageKey }));
        }
        break;

      default:
        console.warn(`Unknown pageType: ${pageType}`);
        break;
    }
  };

  const list = () => (
    <div
      role="presentation"
      onClick={(event) => {
        if (!editMode) toggleDrawer(false)(event);
      }}
      onKeyDown={(event) => {
        if (!editMode) toggleDrawer(false)(event);
      }}
      style={{
        backgroundColor: "#83C5BE",
        color: "white",
        paddinTop: 20,
        paddingBottom: 20,
        height: "100vh",
      }}
    >
      <List>
        {/* Static Links at Start */}
        {["Home", "Profile"].map((text) => (
          <ListItem
            key={text}
            component={Link}
            to={`/${text.replace(/\s+/g, "").toLowerCase()}`}
            sx={{ cursor: "pointer" }}
          >
            <ListItemIcon>{getIcon(text)}</ListItemIcon>
            <ListItemText primary={text} sx={{ color: "white" }} />
          </ListItem>
        ))}

        {/* Dynamic Pages from Redux */}
        {Array.isArray(pageConfigs) &&
          pageConfigs
            .filter((config) => {
              return config.pageType === "story";
            })
            .map((config) => (
              <ListItem
                key={config.storageKey && config.pageType === "story"}
                onClick={() =>
                  handleNavigation(`/stories/${config.storageKey}`)
                }
                sx={{ cursor: "pointer" }}
              >
                <ListItemIcon>
                  {<MenuBookIcon sx={{ color: "white" }} />}
                </ListItemIcon>

                {editMode && editingTitle?.storageKey === config.storageKey ? (
                  <TextField
                    value={editingTitle.title}
                    onChange={(e) =>
                      handleEditTitleChange(e, config.storageKey)
                    }
                    onBlur={() =>
                      handleSaveEditTitle(
                        config.storageKeytitle,
                        editingTitle,
                        config.pageType,
                        config.storyLabel
                      )
                    }
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        handleSaveEditTitle(
                          config.storageKey,
                          editingTitle,
                          config.pageType,
                          config.storyLabel
                        ); // Save on pressing Enter
                      }
                    }}
                    // Save on blur
                    autoFocus
                  />
                ) : (
                  <ListItemText
                    primary={config.pageTitle}
                    sx={{ color: "white" }}
                    onClick={() => {
                      if (!editMode)
                        handleNavigation(`/stories/${config.storageKey}`);
                    }}
                  />
                )}
                {editMode && (
                  <>
                    <IconButton
                      onClick={() =>
                        setEditingTitle({
                          storageKey: config.storageKey,
                          title: config.pageTitle,
                        })
                      }
                    >
                      <EditIcon sx={{ color: "white" }} />
                    </IconButton>
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        toggleEditMode();
                        handleDeletePage(config.storageKey, config.pageType);
                      }}
                    >
                      <DeleteIcon sx={{ color: "white" }} />
                    </IconButton>
                  </>
                )}
              </ListItem>
            ))}

        {/* Dynamic Pages from Redux */}
        {Array.isArray(pageConfigs) &&
          pageConfigs
            .filter((config) => config.pageType === "photo")
            .map((config) => (
              <ListItem
                key={config.storageKey && config.pageType === "photo"}
                onClick={() => handleNavigation(`/photos/${config.storageKey}`)}
                sx={{ cursor: "pointer" }}
              >
                <ListItemIcon>
                  {<PhotoLibraryIcon sx={{ color: "white" }} />}
                </ListItemIcon>

                {editMode && editingTitle?.storageKey === config.storageKey ? (
                  <TextField
                    value={editingTitle.title}
                    onChange={(e) =>
                      handleEditTitleChange(e, config.storageKey)
                    }
                    onBlur={() =>
                      handleSaveEditTitle(
                        config.storageKey,
                        editingTitle,
                        config.pageType,
                        config.storyLabel
                      )
                    }
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        handleSaveEditTitle(
                          config.storageKey,
                          editingTitle,
                          config.pageType,
                          config.storyLabel
                        ); // Save on pressing Enter
                      }
                    }}
                    // Save on blur
                    autoFocus
                  />
                ) : (
                  <ListItemText
                    primary={config.pageTitle}
                    sx={{ color: "white" }}
                    onClick={() => {
                      if (!editMode)
                        handleNavigation(`/photos/${config.storageKey}`);
                    }}
                  />
                )}
                {editMode && (
                  <>
                    <IconButton
                      onClick={() =>
                        setEditingTitle({
                          storageKey: config.storageKey,
                          title: config.pageTitle,
                        })
                      }
                    >
                      <EditIcon sx={{ color: "white" }} />
                    </IconButton>
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        toggleEditMode();
                        handleDeletePage(config.storageKey, config.pageType);
                      }}
                    >
                      <DeleteIcon sx={{ color: "white" }} />
                    </IconButton>
                  </>
                )}
              </ListItem>
            ))}

        {/* Dynamic Pages from Redux */}
        {Array.isArray(pageConfigs) &&
          pageConfigs
            .filter((config) => config.pageType === "video")
            .map((config) => (
              <ListItem
                key={config.storageKey && config.pageType === "video"}
                onClick={() => handleNavigation(`/videos/${config.storageKey}`)}
                sx={{ cursor: "pointer" }}
              >
                <ListItemIcon>
                  {<VideoLibraryIcon sx={{ color: "white" }} />}
                </ListItemIcon>

                {editMode && editingTitle?.storageKey === config.storageKey ? (
                  <TextField
                    value={editingTitle.title}
                    onChange={(e) =>
                      handleEditTitleChange(e, config.storageKey)
                    }
                    onBlur={() =>
                      handleSaveEditTitle(
                        config.storageKey,
                        editingTitle,
                        config.pageType,
                        config.storyLabel
                      )
                    } // Save on blur
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        handleSaveEditTitle(
                          config.storageKey,
                          editingTitle,
                          config.pageType,
                          config.storyLabel
                        ); // Save on pressing Enter
                      }
                    }}
                    // Save on blur
                    autoFocus
                  />
                ) : (
                  <ListItemText
                    primary={config.pageTitle}
                    sx={{ color: "white" }}
                    onClick={() => {
                      if (!editMode)
                        handleNavigation(`/videos/${config.storageKey}`);
                    }}
                  />
                )}
                {editMode && (
                  <>
                    <IconButton
                      onClick={() =>
                        setEditingTitle({
                          storageKey: config.storageKey,
                          title: config.pageTitle,
                        })
                      }
                    >
                      <EditIcon sx={{ color: "white" }} />
                    </IconButton>
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        toggleEditMode();
                        handleDeletePage(config.storageKey, config.pageType);
                      }}
                    >
                      <DeleteIcon sx={{ color: "white" }} />
                    </IconButton>
                  </>
                )}
              </ListItem>
            ))}

        {["My Documents", "Contact Us"].map((text) => (
          <ListItem
            key={text}
            component={Link}
            to={`/${text.replace(/\s+/g, "").toLowerCase()}`}
            sx={{ cursor: "pointer" }}
          >
            <ListItemIcon>{getIcon(text)}</ListItemIcon>
            <ListItemText primary={text} sx={{ color: "white" }} />
          </ListItem>
        ))}

        <ListItem onClick={toggleModal} sx={{ cursor: "pointer" }}>
          <ListItemIcon>
            <AddCircleIcon sx={{ color: "white" }} />
          </ListItemIcon>
          <ListItemText primary="Add Page" sx={{ color: "white" }} />
        </ListItem>
        <ListItem
          onClick={(event) => {
            event.stopPropagation();
            toggleEditMode();
          }}
          sx={{ cursor: "pointer" }}
        >
          <ListItemIcon>
            <EditNoteIcon sx={{ color: "white" }} />
          </ListItemIcon>
          <ListItemText primary="Edit Pages" sx={{ color: "white" }} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
      <Modal
        open={modalOpen}
        onClose={toggleModal}
        aria-labelledby="add-page-modal"
      >
        <Box
          sx={{
            p: 4,
            backgroundColor: "white",
            width: "300px",
            margin: "auto",
            mt: 5,
            borderRadius: 2,
          }}
        >
          <NewPageForm closeModal={toggleModal} />{" "}
        </Box>
      </Modal>
    </div>
  );
};

export default NavigationDrawer;
