// store/index.js
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import saveFormDataMiddleware from "../middleware/saveFormDataMiddleware";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";
import { reduxLogger } from "../utils/logger";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: false,
    }).concat(sagaMiddleware, saveFormDataMiddleware);

    if (reduxLogger) {
      middlewares.push(reduxLogger);
    }

    return middlewares;
  },
});

sagaMiddleware.run(rootSaga);

export default store;
